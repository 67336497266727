import {
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

const StatComponent = ({ value, icon, description, money }) => {
  const { palette } = useTheme();
  return (
    <>
      <Card
        sx={{
          bgcolor: "#fff",
          width: "100%",
          // boxShadow: "rgb(255, 255, 255) 0px 0px 10px 0px",
        }}
      >
        <CardContent>
          <IconButton
            sx={{
              background:
                "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
              mb: 2,
            }}
          >
            {icon}
          </IconButton>
          <Typography
            variant="h4"
            sx={{ color: "#001322", mb: "1px", fontWeight: 700 }}
          >
            {money !== "" ? money + value : value}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "#001322", mb: 0, fontWeight: 700 }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default StatComponent;
