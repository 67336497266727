import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobTypeAction,
  editSingleJobTypeAction,
  typeLoadSingleAction,
} from "../../redux/actions/jobTypeAction";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

const validationSchema = yup.object({
  jobTypeName: yup.string("Enter a Category").required("Category is required"),
});

const DashEditCategory = () => {
  const { user } = useSelector((state) => state.userProfile);
  const { jobType } = useSelector((state) => state.singleType);
  //   console.log(jobType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log(id);

  //job type
  useEffect(() => {
    // dispatch(jobTypeLoadAction());
    if (id) {
      dispatch(typeLoadSingleAction(id));
    }
  }, [id]);

  const formik = useFormik({
    initialValues: {
      _id: jobType?._id,
      user: user && user._id,
      jobTypeName: jobType?.jobTypeName,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      dispatch(editSingleJobTypeAction(values));
      //alert(JSON.stringify(values, null, 2));
      actions.resetForm();
    },
  });

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 4,
        }}
      >
        <Box
          onSubmit={formik.handleSubmit}
          component="form"
          className="form_style border-style"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h5" component="h2" sx={{ pb: 3 }}>
              Update a Category
            </Typography>
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              id="jobTypeName"
              label="category"
              name="jobTypeName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="category name"
              value={formik.values.jobTypeName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.jobTypeName && Boolean(formik.errors.jobTypeName)
              }
              helperText={
                formik.touched.jobTypeName && formik.errors.jobTypeName
              }
            />

            <Button fullWidth variant="contained" type="submit">
              Edit category
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashEditCategory;
