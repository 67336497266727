export const SKILL_LOAD_REQUEST = "SKILL_LOAD_REQUEST";
export const SKILL_LOAD_SUCCESS = "SKILL_LOAD_SUCCESS";
export const SKILL_LOAD_FAIL = "SKILL_LOAD_FAIL";
export const SKILL_LOAD_RESET = "SKILL_LOAD_RESET";

export const CREATE_SKILL_REQUEST = "CREATE_SKILL_REQUEST";
export const CREATE_SKILL_SUCCESS = "CREATE_SKILL_SUCCESS";
export const CREATE_SKILL_FAIL = "CREATE_SKILL_FAIL";
export const CREATE_SKILL_RESET = "CREATE_SKILL_RESET";
export const DELETE_SKILL_REQUEST = "DELETE_SKILL_REQUEST";
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_FAIL = "DELETE_SKILL_FAIL";
