import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Chip, IconButton, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";

const NewCardElement = ({
  jobTitle,
  experience,
  experienceYears,
  companyName,
  companyLogo,
  jobSalary,
  description,
  category,
  deadline,
  location,
  id,
  skills,
}) => {
  const date = new Date(deadline);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const TotalExperience =
    experience === "fresher" ? "Fresher" : experienceYears;

  const { palette } = useTheme();
  // console.log(jobTitle);

  return (
    <CardContent
      sx={{
        bgcolor: palette.primary.white,
        border: "1px solid #e0e0e0",
        height: "100%",
        maxWidth: "450px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "10px",
        // boxShadow: "0 0px 10px 0 #fff",
      }}
    >
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <img
            src={companyLogo}
            alt="company logo"
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
          <Box variant="div" sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ color: palette.textPrimary, fontWeight: 600 }}
            >
              {jobTitle}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              sx={{ color: palette.textPrimary, fontWeight: 600, fontSize: 16 }}
            >
              {companyName}
            </Typography>
          </Box>
        </Box>

        <Typography variant="p" sx={{ color: palette.textPrimary }}>
          {description.split(" ").slice(0, 15).join(" ") + "..."}
        </Typography>

        <Chip
          label={category}
          size="small"
          sx={{
            width: "fit-content",
            bgcolor: "#60656F",
            color: "white",
            fontWeight: 500,
            fontSize: 14,
          }}
        />
      </Box>
      <span
        style={{
          display: "block",
          width: "100%",
          height: "1px",
          backgroundColor: "#CACACA",
          margin: "10px 0",
        }}
      />

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ fontSize: 16, color: palette.secondary.main, fontWeight: 500 }}
        >
          <IconButton>
            <LocationOnIcon
              sx={{ color: palette.secondary.main, fontSize: 18 }}
            />
          </IconButton>{" "}
          {location}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            color: palette.secondary.main,
            fontWeight: 500,
          }}
          component="p"
        >{`Salary: ${jobSalary}`}</Typography>
      </Box>

      <Link
        style={{
          textDecoration: "none",
          boxShadow: 0,
          color: "inherit",
        }}
        to={`/job/${id}`}
      >
        <Button
          disableElevation
          variant="contained"
          size="small"
          fullWidth
          sx={{
            backgroundColor: "inherit",
            fontWeight: 500,
            fontSize: 16,
            border: "1px solid #2B4360",
            textTransform: "none",
            padding: "10px 20px",
            py: 1.5,
            mt: 1,
            color: "#3C1F7D",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#3C1F7D",
              color: "#fff",
            },
          }}
        >
          <Box sx={{ color: "inherit", "&:hover": { color: "inherit" } }}>
            More Details
          </Box>
        </Button>
      </Link>
    </CardContent>
  );
};

export default NewCardElement;
