import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { jobTypeLoadAction } from "../../redux/actions/jobTypeAction";
import { registerAjobAction } from "../../redux/actions/jobAction";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { skillLoadAction } from "../../redux/actions/skillAction";

// Validation schema for the form fields
const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  companyName: yup.string().required("Company Name is required"),
  description: yup
    .string()
    .min(6, "Description should be at least 6 characters")
    .required("Description is required"),
  salary: yup.string().required("Salary is required"),
  location: yup.string().required("Location is required"),
  link: yup.string().url("Invalid URL format").required("Link is required"),
  jobType: yup.string().required("Category is required"),
  deadline: yup.date().required("Deadline is required"),
  experience: yup.string().required("Experience level is required"),
  companyLogo: yup.mixed().required("Company logo is required"),
  skills: yup.array().of(yup.string()).min(1, "At least one skill is required"),
  source: yup.string().required("Source is required"),
});

const DashCreateJob = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jobTypeLoadAction());
    dispatch(skillLoadAction());
  }, [dispatch]);

  const { jobType } = useSelector((state) => state.jobTypeAll);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const { skill: skillData } = useSelector((state) => state.skillAll);
  const [logoPreview, setLogoPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(0);

  const formik = useFormik({
    initialValues: {
      title: "",
      companyName: "",
      description: "",
      salary: "",
      location: "",
      link: "",
      source: "",
      jobType: "",
      skills: [],
      deadline: null,
      experience: "",
      experienceYears: "",
      companyLogo: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      try {
        const storageRef = ref(
          storage,
          `companyLogos/${values.companyLogo.name}`
        );

        await uploadBytes(storageRef, values.companyLogo);
        const logoURL = await getDownloadURL(storageRef);

        const updatedValues = {
          ...values,
          companyLogo: logoURL,
        };

        dispatch(registerAjobAction(updatedValues));

        actions.resetForm();
        setSelectedSkills([]);
        setLogoPreview(null);
        formik.setFieldValue("companyLogo", null);
        formik.setFieldValue("deadline", null);
        setFileInputKey((prevKey) => prevKey + 1);

        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
          fileInput.value = "";
        }
      } catch (error) {
        console.error("Error uploading logo or submitting form: ", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleSkillsChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      setSelectedSkills(typeof value === "string" ? value.split(",") : value);
      formik.setFieldValue(
        "skills",
        typeof value === "string" ? value.split(",") : value
      );
    },
    [formik]
  );

  const handleLogoChange = useCallback(
    (event) => {
      const file = event.currentTarget.files[0];
      if (file) {
        formik.setFieldValue("companyLogo", file);
        const reader = new FileReader();
        reader.onload = () => {
          setLogoPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        formik.setFieldValue("companyLogo", null);
        setLogoPreview(null);
      }
    },
    [formik]
  );

  // console.log(formik.errors);
  // console.log(formik.values);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: 4,
        px: 2,
      }}
    >
      <Box
        onSubmit={formik.handleSubmit}
        component="form"
        sx={{
          backgroundColor: "#fff",
          p: 4,
          borderRadius: "8px",
          boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{ pb: 3, textAlign: "center" }}
        >
          Register a Job
        </Typography>
        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="title"
          label="Job Title"
          name="title"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="companyName"
          label="Company Name"
          name="companyName"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Company Name"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.companyName && Boolean(formik.errors.companyName)
          }
          helperText={formik.touched.companyName && formik.errors.companyName}
        />
        <Button
          variant="contained"
          component="label"
          fullWidth
          key={fileInputKey}
        >
          Upload Company Logo
          <input
            type="file"
            hidden
            onChange={handleLogoChange}
            accept="image/*"
          />
        </Button>
        {formik.errors.companyLogo && (
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "left", my: 1 }}
          >
            {formik.errors.companyLogo}
          </Typography>
        )}

        {logoPreview && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="body2" sx={{ mb: 1 }}>
              Logo Preview:
            </Typography>
            <img
              src={logoPreview}
              alt="Logo Preview"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </Box>
        )}
        <TextField
          sx={{ my: 3 }}
          fullWidth
          id="description"
          name="description"
          label="Description"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
        <FormControl
          fullWidth
          sx={{ mb: 3 }}
          variant="outlined"
          error={formik.touched.experience && Boolean(formik.errors.experience)}
        >
          <InputLabel id="experience-label">Experience</InputLabel>
          <Select
            labelId="experience-label"
            id="experience"
            name="experience"
            value={formik.values.experience}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Experience"
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="fresher">Fresher</MenuItem>
            <MenuItem value="experienced">Experienced</MenuItem>
          </Select>
          {formik.touched.experience && formik.errors.experience && (
            <FormHelperText>{formik.errors.experience}</FormHelperText>
          )}
        </FormControl>

        {formik.values.experience === "experienced" && (
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="experienceYears"
            name="experienceYears"
            label="Years of Experience"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Years of Experience"
            value={formik.values.experienceYears}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.experienceYears &&
              Boolean(formik.errors.experienceYears)
            }
            helperText={
              formik.touched.experienceYears && formik.errors.experienceYears
            }
          />
        )}

        <Box
          sx={{
            mb: 3,
            width: "100%",
          }}
        >
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="skills-label">Skills</InputLabel>
            <Select
              labelId="skills-label"
              id="skills"
              name="skills"
              label="Skills"
              placeholder="Skills"
              multiple
              value={selectedSkills}
              onChange={handleSkillsChange}
              error={formik.touched.skills && Boolean(formik.errors.skills)}
              input={
                <OutlinedInput
                  label="Skills"
                  error={formik.touched.skills && Boolean(formik.errors.skills)}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {skillData &&
                skillData.map((skill) => (
                  <MenuItem key={skill._id} value={skill.skillName}>
                    {skill.skillName}
                  </MenuItem>
                ))}
            </Select>
            {formik.touched.skills && formik.errors.skills && (
              <FormHelperText sx={{ color: "red" }}>
                {formik.errors.skills}
              </FormHelperText>
            )}
          </FormControl>
        </Box>

        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="salary"
          name="salary"
          label="Salary"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Salary"
          value={formik.values.salary}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.salary && Boolean(formik.errors.salary)}
          helperText={formik.touched.salary && formik.errors.salary}
        />

        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="location"
          name="location"
          label="Location"
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Location"
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
        />
        <TextField
          sx={{ mb: 3 }}
          fullWidth
          id="link"
          name="link"
          label="Link"
          type="url"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="Link"
          value={formik.values.link}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.link && Boolean(formik.errors.link)}
          helperText={formik.touched.link && formik.errors.link}
        />

        <TextField
          fullWidth
          id="source"
          name="source"
          label="Source"
          type="text"
          placeholder="Source"
          value={formik.values.source}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.source && Boolean(formik.errors.source)}
          helperText={formik.touched.source && formik.errors.source}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mb: 3 }}
        />

        <TextField
          sx={{ mb: 3 }}
          fullWidth
          className="px-2 my-2"
          variant="outlined"
          name="jobType"
          id="jobType"
          select
          label="Category"
          value={formik.values.jobType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.jobType && Boolean(formik.errors.jobType)}
          helperText={formik.touched.jobType && formik.errors.jobType}
        >
          <MenuItem key={""} value={""}></MenuItem>
          {jobType &&
            jobType.map((cat) => (
              <MenuItem key={cat._id} value={cat._id}>
                {cat.jobTypeName}
              </MenuItem>
            ))}
        </TextField>

        <DatePicker
          label="Deadline"
          value={formik.values.deadline}
          onChange={(value) => {
            formik.setFieldValue("deadline", value);
            formik.setTouched({ ...formik.touched, deadline: true });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              sx={{
                mb: 3,
                "& .MuiInputBase-root": {
                  paddingRight: "0px",
                },
                "& .MuiInputBase-input": {
                  paddingRight: "0px",
                },
              }}
              error={formik.touched.deadline && Boolean(formik.errors.deadline)}
              helperText={formik.touched.deadline && formik.errors.deadline}
            />
          )}
          sx={{ width: "100%" }} // Ensure the DatePicker component itself is full width
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          sx={{
            py: 2,
            mt: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "Register Job"}
        </Button>
      </Box>
    </Box>
  );
};

export default DashCreateJob;
