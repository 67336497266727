import { toast } from "react-toastify";
import {
  ALL_USER_LOAD_FAIL,
  ALL_USER_LOAD_REQUEST,
  ALL_USER_LOAD_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  USER_APPLY_JOB_FAIL,
  USER_APPLY_JOB_REQUEST,
  USER_APPLY_JOB_SUCCESS,
  USER_EDIT_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_LOAD_FAIL,
  USER_LOAD_REQUEST,
  USER_LOAD_SINGLE_FAIL,
  USER_LOAD_SINGLE_REQUEST,
  USER_LOAD_SINGLE_SUCCESS,
  USER_LOAD_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
} from "../constants/userConstant";
import apiClient from "../../axiosConfig";

export const userSignInAction = (user) => async (dispatch) => {
  dispatch({ type: USER_SIGNIN_REQUEST });
  try {
    const { data } = await apiClient.post("/api/signin", user);
    // console.log(data);
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });
    // Fetch user profile after successful login
    // const profileResponse = await apiClient.get("/api/me");
    // console.log(profileResponse);
    // localStorage.setItem("userProfile", JSON.stringify(profileResponse.data));
    // dispatch({
    //   type: USER_LOAD_SUCCESS,
    //   payload: profileResponse.data,
    // });
    toast.success("Login Successfully!");
  } catch (error) {
    dispatch({
      type: USER_SIGNIN_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

// user Create action
export const userSignUpAction = (user) => async (dispatch) => {
  dispatch({ type: USER_SIGNUP_REQUEST });
  try {
    // console.log(user);
    const { data } = await apiClient.post("/api/admin/user/create", user);

    dispatch({
      type: USER_SIGNUP_SUCCESS,
      payload: data,
    });
    toast.success("Register Successfully!");
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

// user edit action
export const userEditAction = (user) => async (dispatch) => {
  dispatch({ type: USER_EDIT_REQUEST });
  try {
    // console.log(user);
    const { data } = await apiClient.post(
      `/api/admin/user/edit/${user._id}`,
      user
    );

    dispatch({
      type: USER_EDIT_SUCCESS,
      payload: data,
    });
    toast.success("User Updated Successfully!");
  } catch (error) {
    dispatch({
      type: USER_EDIT_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

// single User action
export const userLoadSingleAction = (id) => async (dispatch) => {
  dispatch({ type: USER_LOAD_SINGLE_REQUEST });
  try {
    const { data } = await apiClient.get(`/api/user/${id}`);
    dispatch({
      type: USER_LOAD_SINGLE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOAD_SINGLE_FAIL,
      payload: error.response.data.error,
    });
  }
};

//delete user action
export const deleteSingleUserAction = (User_id) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });
  try {
    const { data } = await apiClient.delete(
      `/api/admin/user/delete/${User_id}`
    );
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
    toast.success("User deleted successfully");
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

//log out action
export const userLogoutAction = () => async (dispatch) => {
  dispatch({ type: USER_LOGOUT_REQUEST });
  try {
    localStorage.removeItem("userInfo");
    const { data } = await apiClient.get("/api/logout");
    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: data,
    });
    toast.success("Log out successfully!");
  } catch (error) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

//user profile action
export const userProfileAction = () => async (dispatch) => {
  dispatch({ type: USER_LOAD_REQUEST });
  try {
    const { data } = await apiClient.get("/api/me");
    // console.log("user profile", data);
    dispatch({
      type: USER_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LOAD_FAIL,
      payload:
        error.response?.data?.error || error.message || "Something went wrong",
    });
  }
};

//all user action
export const allUserAction = () => async (dispatch) => {
  dispatch({ type: ALL_USER_LOAD_REQUEST });
  try {
    // console.log("all user action");
    const { data } = await apiClient.get("/api/allusers");
    // console.log("all user", data);
    dispatch({
      type: ALL_USER_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_USER_LOAD_FAIL,
      payload: error.response.data.error,
    });
  }
};

// user data pagination

export const allUsersPaginationAction =
  (page = 1) =>
  async (dispatch) => {
    dispatch({ type: ALL_USER_LOAD_REQUEST });
    try {
      const { data } = await apiClient.get(`/api/allusers?pageNumber=${page}`);
      dispatch({
        type: ALL_USER_LOAD_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: ALL_USER_LOAD_FAIL,
        payload: e.response.data.error,
      });
    }
  };

//user job apply action
export const userApplyJobAction = (job) => async (dispatch) => {
  dispatch({ type: USER_APPLY_JOB_REQUEST });
  try {
    const { data } = await apiClient.post("/api/user/jobhistory", job);

    dispatch({
      type: USER_APPLY_JOB_SUCCESS,
      payload: data,
    });
    // redirect
    window.location.href = "";
    // toast.success("Apply Successfully for this Job!");
  } catch (error) {
    dispatch({
      type: USER_APPLY_JOB_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};
