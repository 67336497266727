import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import {
  deleteJobReducer,
  loadJobReducer,
  loadJobSingleReducer,
  registerAjobReducer,
  updateJobReducer,
} from "./reducers/jobReducer";

import {
  createJobTypeReducer,
  deleteTypeReducer,
  loadJobTypeReducer,
  loadTypeReducer,
} from "./reducers/jobTypeReducer";

import {
  allUserReducer,
  allUserPaginationReducer,
  deleteUserReducer,
  loadUserSingleReducer,
  updateUserReducer,
  userApplyJobReducer,
  userReducerLogout,
  userReducerProfile,
  userReducerSignIn,
  userReducerSignUp,
} from "./reducers/userReducer";

import { modeReducer } from "./reducers/themeModeReducer";
import { createSkillReducer, loadSkillReducer } from "./reducers/skillReducer";

//combine reducers
const reducer = combineReducers({
  loadJobs: loadJobReducer,
  jobTypeAll: loadJobTypeReducer,
  skillAll: loadSkillReducer,
  signIn: userReducerSignIn,
  logOut: userReducerLogout,
  userProfile: userReducerProfile,
  singleJob: loadJobSingleReducer,
  singleUser: loadUserSingleReducer,
  singleType: loadTypeReducer,
  deleteType: deleteTypeReducer,
  userJobApplication: userApplyJobReducer,
  allUsers: allUserReducer,
  userPaginations: allUserPaginationReducer,
  updateUser: updateUserReducer,
  deleteUser: deleteUserReducer,
  signUp: userReducerSignUp,
  mode: modeReducer,
  registerJob: registerAjobReducer,
  deleteJob: deleteJobReducer,
  createJobType: createJobTypeReducer,
  createSkill: createSkillReducer,
  updateJob: updateJobReducer,
});

//initial state
let initialState = {
  signIn: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null,
  },
  mode: {
    mode: "light",
  },
};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
