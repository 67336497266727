import {
  Box,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { jobTypeLoadAction } from "../../redux/actions/jobTypeAction";
import {
  editSingleJobAction,
  jobLoadSingleAction,
} from "../../redux/actions/jobAction";
import { useNavigate, useParams } from "react-router-dom";
import { EDIT_JOB_RESET } from "../../redux/constants/jobconstant";
import { DatePicker } from "@mui/x-date-pickers";
import { skillLoadAction } from "../../redux/actions/skillAction";

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  companyName: yup.string().required("Company Name is required"),
  description: yup
    .string()
    .min(6, "Description should be at least 6 characters")
    .required("Description is required"),
  salary: yup.string().required("Salary is required"),
  location: yup.string().required("Location is required"),
  link: yup.string().url("Invalid URL format").required("Link is required"),
  jobType: yup.string().required("Category is required"),
  deadline: yup.date().required("Deadline is required"),
  experience: yup.string().required("Experience level is required"),
  // experienceYears: yup.string().when("experience", {
  //   is: "experience",
  //   then: yup.string().required("Years of experience is required"),
  //   otherwise: yup.string(),
  // }),
  companyLogo: yup.mixed().required("Company logo is required"),
  skills: yup.array().of(yup.string()).min(1, "At least one skill is required"),
});

// Available skill options
const skillOptions = ["CSS", "JavaScript", "React", "Node.js", "Python"];

const DashEditJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { skill: skillData } = useSelector((state) => state.skillAll);

  //job type
  useEffect(() => {
    dispatch(jobTypeLoadAction());
    dispatch(skillLoadAction());
    if (id) {
      dispatch(jobLoadSingleAction(id));
    }
  }, [id]);

  const { jobType } = useSelector((state) => state.jobTypeAll);
  const { skill } = useSelector((state) => state.skillAll);
  // console.log(skill);
  const { singleJob, loading } = useSelector((state) => state.singleJob);
  const { success } = useSelector((state) => state.updateJob);
  const [logoPreview, setLogoPreview] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const formik = useFormik({
    initialValues: {
      _id: singleJob?._id,
      title: singleJob?.title || "",
      companyName: singleJob?.companyName || "",
      description: singleJob?.description || "",
      salary: singleJob?.salary || "",
      location: singleJob?.location || "",
      link: singleJob?.link || "",
      available: singleJob?.available || false,
      jobType: singleJob?.jobType?._id || "",
      companyLogo: singleJob?.companyLogo || "",
      skills: singleJob?.skills || [],
      experience: singleJob?.experience || "",
      experienceYears: singleJob?.experienceYears || "",
      deadline: singleJob?.deadline ? new Date(singleJob.deadline) : null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      dispatch(editSingleJobAction(values));
      actions.resetForm();
    },
  });

  //redirect after successfull update
  useEffect(() => {
    if (success && success === true) {
      setTimeout(() => {
        dispatch({ type: EDIT_JOB_RESET });
        navigate("/admin/jobs");
      }, 800);
    }
  }, [success && success]);

  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSkills(typeof value === "string" ? value.split(",") : value);
    formik.setFieldValue(
      "skills",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleLogoChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("companyLogo", file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pt: 4,
      }}
    >
      <Box
        onSubmit={formik.handleSubmit}
        component="form"
        className="form_style border-style"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h5" component="h2" sx={{ pb: 3 }}>
            Register a Job
          </Typography>
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="title"
            label="Job Title"
            name="title"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="companyName"
            label="Company Name"
            name="companyName"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Company Name"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
          {/* Upload Company Logo */}
          <Button
            variant="contained"
            component="label"
            fullWidth
            sx={{ mb: 3 }}
          >
            Upload Company Logo
            <input type="file" hidden onChange={handleLogoChange} />
          </Button>
          {logoPreview && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography variant="body2" sx={{ mb: 1 }}>
                Logo Preview:
              </Typography>
              <img
                src={logoPreview}
                alt="Logo Preview"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            </Box>
          )}
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="description"
            name="description"
            label="Description"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            className="px-2 my-2"
            variant="outlined"
            name="experience"
            id="experience"
            select
            label="Experience"
            value={formik.values.experience}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.experience && Boolean(formik.errors.experience)
            }
            helperText={formik.touched.experience && formik.errors.experience}
          >
            <MenuItem value="fresher">Fresher</MenuItem>
            <MenuItem value="experienced">Experienced</MenuItem>
          </TextField>

          {formik.values.experience === "experienced" && (
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              id="experienceYears"
              name="experienceYears"
              label="Years of Experience"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Years of Experience"
              value={formik.values.experienceYears}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.experienceYears &&
                Boolean(formik.errors.experienceYears)
              }
              helperText={
                formik.touched.experienceYears && formik.errors.experienceYears
              }
            />
          )}

          <Box sx={{ mb: 3, width: "100%" }}>
            <DatePicker
              label="Deadline"
              onChange={(value) => formik.setFieldValue("deadline", value)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ mb: 3 }}
                  fullWidth
                  id="deadline"
                  name="deadline"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.deadline && Boolean(formik.errors.deadline)
                  }
                  helperText={formik.touched.deadline && formik.errors.deadline}
                />
              )}
            />
          </Box>

          {/* Skills */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="skills-label">Skills</InputLabel>
            <Select
              labelId="skills-label"
              multiple
              id="skills"
              name="skills"
              value={selectedSkills}
              onChange={handleSkillsChange}
              input={<OutlinedInput id="select-multiple-chip" label="Skills" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {skillData &&
                skillData.map((skill) => (
                  <MenuItem key={skill._id} value={skill.skillName}>
                    {skill.skillName}
                  </MenuItem>
                ))}
            </Select>
            {formik.touched.skills && formik.errors.skills && (
              <Typography color="error">{formik.errors.skills}</Typography>
            )}
          </FormControl>

          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="salary"
            name="salary"
            label="Salary"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Salary"
            value={formik.values.salary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.salary && Boolean(formik.errors.salary)}
            helperText={formik.touched.salary && formik.errors.salary}
          />
          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="location"
            name="location"
            label="Location"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Location"
            value={formik.values.location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.location && Boolean(formik.errors.location)}
            helperText={formik.touched.location && formik.errors.location}
          />

          <TextField
            sx={{ mb: 3 }}
            fullWidth
            id="link"
            name="link"
            label="Link"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Link"
            value={formik.values.link}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.link && Boolean(formik.errors.link)}
            helperText={formik.touched.link && formik.errors.link}
          />

          <TextField
            sx={{ mb: 3 }}
            fullWidth
            className="px-2 my-2"
            variant="outlined"
            name="jobType"
            id="jobType"
            select
            label="Category"
            value={formik.values.jobType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.jobType && Boolean(formik.errors.jobType)}
            helperText={formik.touched.jobType && formik.errors.jobType}
          >
            <MenuItem key={""} value={""}></MenuItem>
            {jobType &&
              jobType.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.jobTypeName}
                </MenuItem>
              ))}
          </TextField>

          <Button fullWidth variant="contained" type="submit">
            Update job
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DashEditJob;
