// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDhcHzarEuiIQlrZdPzD50DBUdT2nsZ4RQ",
  authDomain: "career-page-ea1de.firebaseapp.com",
  projectId: "career-page-ea1de",
  storageBucket: "career-page-ea1de.appspot.com",
  messagingSenderId: "422322037249",
  appId: "1:422322037249:web:afe93b727f4fab4003d600",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service and Firestore
const storage = getStorage(app);
const firestore = getFirestore(app);

export { storage, firestore };
