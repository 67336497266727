import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import Navbar from "../component/Navbar";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { jobLoadSingleAction } from "../redux/actions/jobAction";
import LoadingBox from "../component/LoadingBox";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerIcon from "@mui/icons-material/Timer";
import LayersIcon from "@mui/icons-material/Layers";
import WorkIcon from "@mui/icons-material/Work";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PlaceIcon from "@mui/icons-material/Place";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import Footer from "../component/Footer";
import SourceIcon from "@mui/icons-material/Source";
import styled from "styled-components";
import BluredCircle from "../images/BluredCircle.png";
const handleShareClick = (platform) => {
  const jobTitle = document.title;
  const jobUrl = window.location.href;
  let shareUrl = "";
  let shareText = `Check out this job: ${jobTitle}`;

  switch (platform) {
    case "linkedin":
      shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        jobUrl
      )}`;
      break;
    case "twitter":
      shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareText
      )}&url=${encodeURIComponent(jobUrl)}`;
      break;
    case "facebook":
      // Using Share Dialog API
      shareUrl = `https://www.facebook.com/dialog/share?app_id=YOUR_APP_ID&display=popup&href=${encodeURIComponent(
        jobUrl
      )}`;
      break;
    case "email":
      shareUrl = `mailto:?subject=${encodeURIComponent(
        jobTitle
      )}&body=${encodeURIComponent(`${shareText}\n\n${jobUrl}`)}`;
      break;

    case "copy":
      navigator.clipboard
        .writeText(jobUrl)
        .then(() => {
          toast.success("Link copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy:", err);
          toast.error("Failed to copy link. Please copy manually.");
        });
      break;
    default:
      break;
  }

  if (shareUrl) {
    window.open(shareUrl, "_blank");
  }
};

const ImageTag = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(5px)",
  top: "0%",
  left: "-10%",

  "@media (max-width: 600px)": {
    width: "320px",
  },
});
const ImageTag2 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(5px)",
  bottom: "0%",
  right: "0%",
});

const SingleJobNewPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const dispatch = useDispatch();
  const { singleJob, loading } = useSelector((state) => state.singleJob);
  const { id } = useParams();

  useEffect(() => {
    dispatch(jobLoadSingleAction(id));
  }, [dispatch, id]);

  const applyForAJob = () => {
    if (singleJob?.link) {
      window.location.href = singleJob.link;
    } else {
      toast.error("Login to apply for this job");
    }
  };

  const { palette } = useTheme();

  return (
    <Box
      sx={{
        // background:
        //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
        backgroundColor: palette.primary.main,
        height: "100%",
      }}
    >
      <Navbar />
      <Container
        sx={{ pt: { xs: 2, sm: 3, md: 4 }, pb: 4, minHeight: "100vh" }}
        maxWidth="xl"
      >
        {loading ? (
          <Box sx={{ textAlign: "center" }}>
            <LoadingBox />
          </Box>
        ) : (
          <Stack spacing={4} sx={{ position: "relative", zIndex: 15 }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "start", sm: "center" }}
              justifyContent="space-between"
              spacing={2}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ position: "relative", zIndex: 5 }}
              >
                <ImageTag src={BluredCircle} alt="blured circle" />
                <Avatar
                  sx={{
                    height: { xs: 80, sm: 100, md: 150 },
                    width: { xs: 80, sm: 100, md: 150 },
                    borderRadius: "50%",
                    border: "3px solid #001322",
                    bgcolor: "#fff",
                  }}
                  alt="company logo"
                  src={singleJob?.companyLogo}
                />
                <Box>
                  <Typography
                    variant={isMobile ? "h5" : "h4"}
                    sx={{ color: "#fff", fontWeight: 700 }}
                  >
                    {singleJob?.title}
                  </Typography>
                  <Typography
                    variant={isMobile ? "h6" : "h5"}
                    sx={{ color: "#fff", fontWeight: 700 }}
                  >
                    at {singleJob?.companyName}
                  </Typography>
                </Box>
              </Stack>
              <Button
                onClick={applyForAJob}
                sx={{
                  fontSize: { xs: "14px", sm: "16px" },
                  color: "#000",
                  textTransform: "none",
                  bgcolor: "#fff",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  fontWeight: "bold",
                  "&:hover": {
                    bgcolor: "#3C1F7D",
                    color: "#fff",
                  },
                }}
                variant="contained"
                // startIcon={<ArrowForwardIcon />}
                endIcon={<ArrowForwardIcon />}
              >
                Apply Now
              </Button>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={4}
              sx={{ position: "relative", zIndex: 5 }}
              overflow={"hidden"}
            >
              <Box flex={1}>
                <JobOverview
                  singleJob={singleJob}
                  TotalExperience={
                    singleJob?.experience ? singleJob?.experience : "N/A"
                  }
                />
                <SalaryAndLocation singleJob={singleJob} />
                {/* <ShareJob /> */}
              </Box>
              <Box
                flex={1}
                sx={{ display: "flex", flexDirection: "column", gap: 3 }}
              >
                <JobDescription singleJob={singleJob} />
                <JobSkills singleJob={singleJob} />
              </Box>
              <ImageTag2 src={BluredCircle} alt="blured circle" />
            </Stack>
          </Stack>
        )}
      </Container>
      <Footer />
    </Box>
  );
};

const JobOverview = ({ singleJob, TotalExperience }) => (
  <Box
    sx={{
      background:
        "linear-gradient(94.89deg, rgba(19, 7, 46, 0.85) 1.2%, rgba(63, 33, 130, 0.85) 97.79%)",
      p: 3,
      borderRadius: 2,
      mb: 3,
      position: "relative",
      zIndex: 10,
    }}
  >
    <Typography sx={{ color: "#fff", mb: 2, fontWeight: 700 }} variant="h5">
      Job Overview
    </Typography>
    <Grid container spacing={3}>
      <OverviewItem
        icon={<CalendarTodayIcon sx={{ color: "#80ABDB" }} fontSize="large" />}
        title="Job Posted:"
        value={
          singleJob ? new Date(singleJob?.createdAt).toDateString() : "N/A"
        }
      />
      <OverviewItem
        icon={<TimerIcon sx={{ color: "#80ABDB" }} fontSize="large" />}
        title="Job expires in:"
        value={singleJob ? new Date(singleJob?.deadline).toDateString() : "N/A"}
      />
      <OverviewItem
        icon={<LayersIcon sx={{ color: "#80ABDB" }} fontSize="large" />}
        title="Job Level:"
        value={TotalExperience}
      />
      <OverviewItem
        icon={<WorkIcon sx={{ color: "#80ABDB" }} fontSize="large" />}
        title="Experience:"
        value={
          !singleJob?.experienceYears.length
            ? "N/A"
            : singleJob?.experienceYears
        }
      />
    </Grid>
  </Box>
);

const OverviewItem = ({ icon, title, value }) => (
  <Grid item xs={12} sm={6} sx={{ position: "relative", zIndex: 10 }}>
    <Stack direction="row" spacing={2} alignItems="center">
      {icon}
      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 700 }} variant="subtitle1">
          {title}
        </Typography>
        <Typography
          sx={{ color: "#fff", textTransform: "capitalize" }}
          variant="body1"
        >
          {value}
        </Typography>
      </Box>
    </Stack>
  </Grid>
);

const SalaryAndLocation = ({ singleJob }) => (
  <Box
    sx={{
      background:
        "linear-gradient(94.89deg, rgba(19, 7, 46, 0.85) 1.2%, rgba(63, 33, 130, 0.85) 97.79%)",
      p: 3,
      borderRadius: 2,
      mb: 3,
      position: "relative",
      zIndex: 10,
    }}
  >
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <CurrencyRupeeIcon sx={{ color: "#80ABDB" }} fontSize="large" />
        <Box>
          <Typography
            sx={{ color: "#fff", fontWeight: 700 }}
            variant="subtitle1"
          >
            Salary (INR):
          </Typography>
          <Typography sx={{ color: "#fff" }} variant="body1">
            {singleJob?.salary || "N/A"}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ borderBottom: "1px solid #80ABDB" }}></Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <PlaceIcon sx={{ color: "#80ABDB" }} fontSize="large" />
        <Box>
          <Typography
            sx={{ color: "#fff", fontWeight: 700 }}
            variant="subtitle1"
          >
            Job Location:
          </Typography>
          <Typography sx={{ color: "#fff" }} variant="body1">
            {singleJob?.location || "N/A"}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ borderBottom: "1px solid #80ABDB" }}></Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <SourceIcon sx={{ color: "#80ABDB" }} fontSize="large" />
        <Box>
          <Typography
            sx={{ color: "#fff", fontWeight: 700 }}
            variant="subtitle1"
          >
            Job Source:
          </Typography>
          <Typography sx={{ color: "#fff" }} variant="body1">
            {singleJob?.source || "N/A"}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  </Box>
);

// const ShareJob = () => (
//   <Box sx={{ p: 3, borderRadius: 2 }}>
//     <Typography sx={{ color: "#fff", mb: 2 }} variant="h6">
//       Share this job:
//     </Typography>
//     <Stack direction="row" spacing={2} sx={{ flexWrap: "wrap", gap: 1 }}>
//       <ShareButton
//         icon={<LinkIcon />}
//         text="Copy Link"
//         onClick={() => handleShareClick("copy")}
//       />
//       <IconButton
//         icon={<LinkedInIcon />}
//         onClick={() => handleShareClick("linkedin")}
//       />
//       <IconButton
//         icon={<TwitterIcon />}
//         onClick={() => handleShareClick("twitter")}
//       />
//       <IconButton
//         icon={<FacebookIcon />}
//         onClick={() => handleShareClick("facebook")}
//       />
//       <IconButton
//         icon={<EmailIcon />}
//         onClick={() => handleShareClick("email")}
//       />
//     </Stack>
//   </Box>
// );

const ShareButton = ({ icon, text, onClick }) => (
  <Button
    sx={{
      display: "flex",
      gap: 1,
      bgcolor: "#fff",
      borderRadius: "5px",
      padding: 1,
      "&:hover": { bgcolor: "#f0f0f0" },
    }}
    onClick={onClick}
  >
    {React.cloneElement(icon, { sx: { color: "#0A65CC" }, fontSize: "large" })}
    <Typography sx={{ color: "#0A65CC" }}>{text}</Typography>
  </Button>
);

const IconButton = ({ icon, onClick }) => (
  <Button
    sx={{
      bgcolor: "#fff",
      borderRadius: "5px",
      padding: 1,
      minWidth: 0,
      "&:hover": { bgcolor: "#f0f0f0" },
    }}
    onClick={onClick}
  >
    {React.cloneElement(icon, { sx: { color: "#0A65CC" }, fontSize: "large" })}
  </Button>
);

const JobDescription = ({ singleJob }) => (
  <Box
    sx={{
      background:
        "linear-gradient(94.89deg, rgba(19, 7, 46, 0.85) 1.2%, rgba(63, 33, 130, 0.85) 97.79%)",
      p: 3,
      borderRadius: 2,
      zIndex: 9,
      position: "relative",
    }}
  >
    <Typography sx={{ color: "#fff", mb: 2, fontWeight: 700 }} variant="h5">
      Job Description
    </Typography>
    <Typography sx={{ color: "#fff" }} variant="body1">
      {singleJob?.description}
    </Typography>
  </Box>
);
const JobSkills = ({ singleJob }) => (
  <Box
    sx={{
      background:
        "linear-gradient(94.89deg, rgba(19, 7, 46, 0.85) 1.2%, rgba(63, 33, 130, 0.85) 97.79%)",
      p: 3,
      borderRadius: 2,
      position: "relative",
      zIndex: 8,
    }}
  >
    <Typography sx={{ color: "#fff", mb: 2, fontWeight: 700 }} variant="h5">
      Job Skills
    </Typography>
    {singleJob?.skills?.map((skill, index) => (
      <Chip
        key={index}
        label={skill}
        sx={{
          mb: 1,
          mr: 1,
          color: "#fff",
          bgcolor: "#3C1F7D",
          borderColor: "#fff",
          borderWidth: 1,
          borderStyle: "solid",
          fontWeight: 700,
        }}
      />
    ))}
  </Box>
);

export default SingleJobNewPage;

// new comment
