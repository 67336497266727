import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const UserInfoDashboard = () => {
  const { user } = useSelector((state) => state.userProfile);
  const { palette } = useTheme();
  return (
    <>
      <Box sx={{ maxWidth: "50%", margin: "auto", pt: 10 }}>
        <Card
          sx={{
            minWidth: 275,
            bgcolor: "#fff",
            width: "100%",
            boxShadow: "rgb(255, 255, 255) 0px 0px 10px 0px",
          }}
        >
          <CardContent>
            <Typography
              sx={{ fontSize: 16, fontWeight: 700 }}
              color="#001322"
              gutterBottom
            >
              Personal Info
            </Typography>
            <hr style={{ marginBottom: "30px", borderColor: "#001322" }} />
            {user ? (
              <>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: "#001322" }}
                >
                  First name: {user.firstName}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: "#001322" }}
                >
                  Last name: {user.lastName}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: "#001322" }}
                >
                  E-mail: {user.email}
                </Typography>
                <Typography sx={{ mb: 1.5, pt: 2 }} color="#001322">
                  Status: {user.role === 0 ? "Regular user" : "Admin"}
                </Typography>
              </>
            ) : (
              <Typography
                variant="h6"
                component="div"
                sx={{ color: "#001322" }}
              >
                No user data available.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default UserInfoDashboard;
