import { Typography, Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import StatComponent from "../../component/StatComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import { useSelector } from "react-redux";
import moment from "moment";

const UserDashboard = () => {
  const { user } = useSelector((state) => state.userProfile);

  return (
    <Box
      sx={{
        p: 3,
        background:
          "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
        borderRadius: 2,
      }}
    >
      <Typography variant="h4" sx={{ color: "white", pb: 3 }}>
        User Dashboard
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <StatComponent
          value={user ? moment(user.createdAt).format("YYYY / MM / DD") : "N/A"}
          icon={<CalendarMonthIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
          description="Member since"
          money=""
        />
        <StatComponent
          value={user ? user.jobsHistory.length : "N/A"}
          icon={<WorkIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
          description="Number of jobs submitted"
          money=""
        />
      </Stack>
    </Box>
  );
};

export default UserDashboard;
