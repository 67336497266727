import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSingleJobAction,
  jobLoadAction,
} from "../../redux/actions/jobAction";

const DashJobs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jobLoadAction());
  }, []);

  const [page, setPage] = useState(1);

  // const { success: deleteSuccess } = useSelector((state) => state.deleteJob);
  const { jobs, loading, pages } = useSelector((state) => state.loadJobs);
  let data = [];
  data = jobs !== undefined && jobs.length > 0 ? jobs : [];

  // console.log(data);

  // delete a job by id
  const deleteJobById = (e, id) => {
    if (window.confirm(`You really want to delete product ID: "${id}" ?`)) {
      dispatch(deleteSingleJobAction(id));
      dispatch(jobLoadAction());
      // if (deleteSuccess && deleteSuccess === true) {
      // }
    }
  };

  useEffect(() => {
    dispatch(jobLoadAction(page));
  }, [dispatch, page]);

  const columns = [
    { id: "sno", headerName: "S No.", align: "center" },
    // { id: "id", headerName: "Job ID" },
    { id: "title", headerName: "Job Name" },
    { id: "company", headerName: "Company Name" },
    { id: "category", headerName: "Category" },
    { id: "available", headerName: "Available" },
    { id: "location", headerName: "Location" },
    { id: "salary", headerName: "Salary" },
    { id: "link", headerName: "Link" },
    { id: "actions", headerName: "Actions", align: "center" },
  ];

  return (
    <Box
      sx={{
        padding: { xs: 2, sm: 3, md: 4 },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#fff",
          pb: 3,
          fontWeight: "bold",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
        }}
      >
        Jobs List
      </Typography>
      <Box
        sx={{
          pb: 2,
          display: "flex",
          justifyContent: "flex-end",
          mb: 3,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          component={Link}
          to="/admin/job/create"
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            borderRadius: "8px",
            backgroundColor: "green",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            "&:hover": {
              backgroundColor: "#228B22",
            },
          }}
        >
          Create Job
        </Button>
      </Box>
      {/* <Paper
        sx={{
          bgcolor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            minHeight: "400px",
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #f0f0f0",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f9fafb",
                color: "#1e88e5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#ffffff",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f9fafb",
                color: "#1e88e5",
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "#1e88e5 !important",
              },
              "& .MuiTablePagination-root": {
                color: "#333333",
              },
              "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeader": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
                outline: "none !important",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#f5f5f5",
              },
              "& .MuiCheckbox-root": {
                color: "#1e88e5",
              },
              "& .MuiDataGrid-cellContent": {
                fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
              },
            }}
            rows={data}
            columns={columns.map((column) => ({
              ...column,
              flex: 1,
              minWidth: 150,
            }))}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      </Paper> */}
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          borderRadius: "6px",
        }}
      >
        {!loading ? (
          <Table sx={{ minWidth: 650 }} aria-label="job list table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e3f2fd" }}>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    component="th"
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell align="center">{i + 1}</StyledTableCell>
                  {/* <StyledTableCell title={row._id}>{`${String(row._id).slice(
                  0,
                  8
                )}...`}</StyledTableCell> */}
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>{row.companyName}</StyledTableCell>
                  <StyledTableCell>{row.jobType.jobTypeName}</StyledTableCell>
                  <StyledTableCell>
                    {row.available ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>{row.salary}</StyledTableCell>
                  <StyledTableCell>
                    <a
                      href={row.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#1976d2", textDecoration: "none" }}
                    >
                      Link
                    </a>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      sx={{ display: "flex", gap: 1, justifyContent: "center" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/admin/edit/job/${row._id}`}
                        size="small"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          "&:hover": {
                            backgroundColor: "#1565c0",
                          },
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={(e) => deleteJobById(e, row._id)}
                        size="small"
                        sx={{
                          textTransform: "none",
                          fontWeight: "bold",
                          borderRadius: "8px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          "&:hover": {
                            backgroundColor: "#d32f2f",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Stack
            sx={{ color: "grey.500" }}
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt={10}
            mb={10}
            width="100%"
            minHeight={300}
          >
            <CircularProgress color="success" />
            <Typography>Loading...</Typography>
          </Stack>
        )}
      </TableContainer>
      <Stack spacing={2} py={5}>
        <Pagination
          variant="outlined"
          page={page}
          count={pages === 0 ? 1 : pages}
          onChange={(event, value) => setPage(value)}
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            "& .MuiPaginationItem-root": {
              color: "#fff",
              borderColor: "#fff",
            },
            "& .Mui-selected": {
              backgroundColor: "#1976d2",
              borderColor: "#fff",
              color: "#fff",
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default DashJobs;

const StyledTableCell = ({ children, ...props }) => (
  <TableCell
    {...props}
    sx={{
      fontWeight: props.component === "th" ? "bold" : "normal",
      padding: { xs: "8px 6px", sm: "16px 8px", md: "16px" },
      fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" },
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: { xs: "100px", sm: "150px", md: "200px" },
    }}
  >
    {children}
  </TableCell>
);

const StyledTableRow = ({ children, ...props }) => (
  <TableRow
    {...props}
    sx={{
      "&:nth-of-type(odd)": {
        backgroundColor: "#f9fafb",
      },
      "&:hover": {
        backgroundColor: "#f0f4f8",
      },
      "&:last-child td, &:last-child th": {
        border: 0,
      },
    }}
  >
    {children}
  </TableRow>
);
