import { toast } from "react-toastify";
import {
  CREATE_SKILL_FAIL,
  CREATE_SKILL_REQUEST,
  CREATE_SKILL_SUCCESS,
  DELETE_SKILL_FAIL,
  DELETE_SKILL_REQUEST,
  DELETE_SKILL_SUCCESS,
  SKILL_LOAD_FAIL,
  SKILL_LOAD_REQUEST,
  SKILL_LOAD_SUCCESS,
} from "../constants/skillConstant";
import apiClient from "../../axiosConfig";

// load skill
export const skillLoadAction = () => async (dispatch) => {
  dispatch({ type: SKILL_LOAD_REQUEST });
  try {
    const { data } = await apiClient.get("/api/skills");
    dispatch({
      type: SKILL_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SKILL_LOAD_FAIL,
      payload: error.response.data.error,
    });
  }
};

// create jobs category
export const createSkillAction = (skill) => async (dispatch) => {
  dispatch({ type: CREATE_SKILL_REQUEST });

  try {
    const { data } = await apiClient.post("/api/skill/create", skill);
    dispatch({
      type: CREATE_SKILL_SUCCESS,
      payload: data,
    });
    toast.success("Skill created successfully");
  } catch (error) {
    dispatch({
      type: CREATE_SKILL_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

export const deleteSingleSkillAction = (type_id) => async (dispatch) => {
  dispatch({ type: DELETE_SKILL_REQUEST });
  try {
    const { data } = await apiClient.delete(`/api/skill/delete/${type_id}`);
    dispatch({
      type: DELETE_SKILL_SUCCESS,
      payload: data,
    });
    toast.success("Skill deleted successfully");
    // Refetch the skills data after successful deletion
    dispatch(skillLoadAction());
  } catch (error) {
    dispatch({
      type: DELETE_SKILL_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};
