import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Chip, IconButton, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const CardElement = ({
  jobTitle,
  experience,
  experienceYears,
  companyName,
  companyLogo,
  jobSalary,
  description,
  category,
  deadline,
  location,
  id,
  skills,
}) => {
  const date = new Date(deadline);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getUTCFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const TotalExperience = experience == "fresher" ? "Fresher" : experienceYears;

  const { palette } = useTheme();
  return (
    <Card
      sx={{
        minWidth: 275,
        mb: 3,
        mt: 3,
        bgcolor: palette.primary.white,
        borderRadius: "20px",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 15, color: palette.secondary.main, fontWeight: 500 }}
          gutterBottom
        >
          <IconButton>
            <LocationOnIcon
              sx={{ color: palette.secondary.main, fontSize: 18 }}
            />
          </IconButton>{" "}
          {location}
        </Typography>
        <Avatar
          sx={{ height: "100px", width: "100px" }}
          alt="logo dashboard"
          src={companyLogo}
        ></Avatar>
        <Typography variant="h5" component="div">
          {`Campany: ${companyName}`}
        </Typography>
        <Typography variant="h5" component="div">
          {`Job Title: ${jobTitle}`}
        </Typography>
        <Typography variant="h6" component="div">
          {`Experience: ${TotalExperience}`}
        </Typography>
        {/* Skills section */}
        <Box
          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1.5, mt: 1.5 }}
        >
          {skills.map((skill, index) => (
            <Chip key={index} label={skill} size="small" />
          ))}
        </Box>

        <Typography sx={{}} color="text.secondary">
          {`Category: ${category}`}
        </Typography>
        <Typography sx={{ mb: 1.5 }}>{`Deadline: ${formattedDate}`}</Typography>
        <Typography variant="h7" component="div">
          {`Salary: ${jobSalary}`}
        </Typography>
        <Typography variant="body2">
          Description: {description.split(" ").slice(0, 15).join(" ") + "..."}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          disableElevation
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
          <Link
            style={{ textDecoration: "none", color: "white", boxShadow: 0 }}
            to={`/job/${id}`}
          >
            More Details
          </Link>
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardElement;
