import { Box, styled } from "@mui/material";
import React from "react";
import headerImage from "../images/jobbgg.jpg";
import SearchInputEl from "./SearchInputEl";
import { Opacity } from "@mui/icons-material";

const Header = () => {
  const StyleHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 400,
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
    zIndex: 10,
  }));

  return (
    <>
      <StyleHeader>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchInputEl />
        </div>
      </StyleHeader>
    </>
  );
};

export default Header;
