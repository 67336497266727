import { toast } from "react-toastify";
import {
  DELETE_JOB_FAIL,
  DELETE_JOB_REQUEST,
  DELETE_JOB_SUCCESS,
  EDIT_JOB_FAIL,
  EDIT_JOB_REQUEST,
  EDIT_JOB_SUCCESS,
  JOB_LOAD_FAIL,
  JOB_LOAD_REQUEST,
  JOB_LOAD_SINGLE_FAIL,
  JOB_LOAD_SINGLE_REQUEST,
  JOB_LOAD_SINGLE_SUCCESS,
  JOB_LOAD_SUCCESS,
  REGISTER_JOB_FAIL,
  REGISTER_JOB_REQUEST,
  REGISTER_JOB_SUCCESS,
} from "../constants/jobconstant";
import apiClient from "../../axiosConfig";

export const jobLoadAction =
  (pageNumber, keyword = "", cat = "", location = "") =>
  async (dispatch, getState) => {
    dispatch({ type: JOB_LOAD_REQUEST });
    const { userInfo } = getState().signIn;
    try {
      const { data } = await apiClient.get(
        `/api/jobs/show/?pageNumber=${pageNumber}&keyword=${keyword}&cat=${cat}&location=${location}`
      );
      // console.log(data);

      if (!userInfo) {
        // if user not logged in
        const modifiedData = {
          ...data,
          jobs: data.jobs.map((job) => {
            const { link, ...rest } = job;
            return rest;
          }),
        };

        dispatch({
          type: JOB_LOAD_SUCCESS,
          payload: modifiedData,
        });
      } else {
        dispatch({
          type: JOB_LOAD_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: JOB_LOAD_FAIL,
        payload: error.response.data.error,
      });
    }
  };

// single job action
// export const jobLoadSingleAction = (id) => async (dispatch) => {
//   dispatch({ type: JOB_LOAD_SINGLE_REQUEST });
//   try {
//     const { data } = await apiClient.get(`/api/job/${id}`);
//     dispatch({
//       type: JOB_LOAD_SINGLE_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: JOB_LOAD_SINGLE_FAIL,
//       payload: error.response.data.error,
//     });
//   }
// };

// Job load single action with user authentication check
export const jobLoadSingleAction = (id) => async (dispatch, getState) => {
  dispatch({ type: JOB_LOAD_SINGLE_REQUEST });

  try {
    const { data } = await apiClient.get(`/api/job/${id}`);
    // Get user authentication state from the Redux store
    const { userInfo } = getState().signIn;

    let modifiedData = data;

    if (!userInfo) {
      // If the user is not logged in, modify the job object to remove sensitive information
      const { job } = data;
      const { link, ...rest } = job;
      modifiedData = {
        ...data,
        job: rest,
      };

      dispatch({
        type: JOB_LOAD_SINGLE_SUCCESS,
        payload: modifiedData,
      });
    } else {
      dispatch({
        type: JOB_LOAD_SINGLE_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOB_LOAD_SINGLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//delete single job action
export const deleteSingleJobAction = (job_id) => async (dispatch) => {
  dispatch({ type: DELETE_JOB_REQUEST });
  try {
    const { data } = await apiClient.delete(`/api/job/delete/${job_id}`);
    dispatch({
      type: DELETE_JOB_SUCCESS,
      payload: data,
    });
    toast.success("Job deleted successfully");
  } catch (error) {
    dispatch({
      type: DELETE_JOB_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

//edit single job action
export const editSingleJobAction = (job) => async (dispatch) => {
  dispatch({ type: EDIT_JOB_REQUEST });
  try {
    const { data } = await apiClient.put(`/api/job/update/${job._id}`, job);
    dispatch({
      type: EDIT_JOB_SUCCESS,
      payload: data,
    });
    toast.success("Job updated successfully");
  } catch (error) {
    dispatch({
      type: EDIT_JOB_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

// register job action
export const registerAjobAction = (job) => async (dispatch) => {
  dispatch({ type: REGISTER_JOB_REQUEST });

  try {
    const { data } = await apiClient.post("/api/job/create", job);
    dispatch({
      type: REGISTER_JOB_SUCCESS,
      payload: data,
    });
    toast.success("Job created successfully");
  } catch (error) {
    dispatch({
      type: REGISTER_JOB_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};
