import { Box } from "@mui/material";
import React from "react";
import HeaderTop from "./HeaderTop";
import SidebarAdm from "./Sidebar";

const Layout =
  (Component) =>
  ({ ...props }) => {
    return (
      <>
        <div style={{ display: "flex", minHeight: "100vh" }}>
          <SidebarAdm />
          <Box
            sx={{
              width: "100%",
              // background:
              //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
              backgroundColor: "#13072E",
              minHeight: "100vh",
            }}
          >
            <HeaderTop />
            <Box sx={{ p: 3 }}>
              <Component {...props} />
            </Box>
          </Box>
        </div>
      </>
    );
  };

export default Layout;
