export const JOB_LOAD_REQUEST = "JOB_LOAD_REQUEST";
export const JOB_LOAD_SUCCESS = "JOB_LOAD_SUCCESS";
export const JOB_LOAD_FAIL = "JOB_LOAD_FAIL";
export const JOB_LOAD_RESET = "JOB_LOAD_RESET";

export const JOB_LOAD_SINGLE_REQUEST = "JOB_LOAD_SINGLE_REQUEST";
export const JOB_LOAD_SINGLE_SUCCESS = "JOB_LOAD_SINGLE_SUCCESS";
export const JOB_LOAD_SINGLE_FAIL = "JOB_LOAD_SINGLE_FAIL";
export const JOB_LOAD_SINGLE_RESET = "JOB_LOAD_SINGLE_RESET";

export const REGISTER_JOB_REQUEST = "REGISTER_JOB_REQUEST";
export const REGISTER_JOB_SUCCESS = "REGISTER_JOB_SUCCESS";
export const REGISTER_JOB_FAIL = "REGISTER_JOB_FAIL";
export const REGISTER_JOB_RESET = "REGISTER_JOB_RESET";

export const DELETE_JOB_REQUEST = "DELETE_JOB_REQUEST";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAIL = "DELETE_JOB_FAIL";
export const DELETE_JOB_RESET = "DELETE_JOB_RESET";

export const EDIT_JOB_REQUEST = "EDIT_JOB_REQUEST";
export const EDIT_JOB_SUCCESS = "EDIT_JOB_SUCCESS";
export const EDIT_JOB_FAIL = "EDIT_JOB_FAIL";
export const EDIT_JOB_RESET = "EDIT_JOB_RESET";
