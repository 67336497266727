import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import { useTheme } from "@emotion/react";
import styled from "styled-components";
import BannerImage from "../images/AtsBanner.png";
import Mag from "../images/Mag.png";
import ResumeImage from "../images/Resume.png";
import LeftImage from "../images/Left.png";
import RightImage from "../images/Right.png";
import Act1 from "../images/Act1.png";
import Act2 from "../images/Act2.png";
import Act3 from "../images/Act3.png";
import Act4 from "../images/Act4.png";
import Lists from "../images/Lists.png";
import { Link } from "react-router-dom";

const Ats = () => {
  const { palette } = useTheme();

  // Banner image styled with styled-components
  const Banner = styled("img")({
    // width: "100%",
    height: "auto",
    width: "100%",
    objectFit: "contain",
    // objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    marginBottom: "2rem",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
    border: "none",
  });

  const LeftImageBox = styled("img")({
    width: "200px",
    height: "200px",

    "@media (max-width: 600px)": {
      display: "none",
    },
  });

  const RightImageBox = styled("img")({
    width: "200px",
    height: "200px",

    "@media (max-width: 600px)": {
      display: "none",
    },
  });

  const GridImage = styled("img")({
    width: "200px",
    height: "140px",
    objectFit: "contain",
  });

  const ListsImage = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "contain",
  });

  const GridJson = [
    {
      image: Act1,
      title: "Upload your resume",
      description: `Upload your resume to the resume score checker (or
build a new one from scratch), and go to the
dashboard to view your score.`,
    },
    {
      image: Act2,
      title: "Review checker report",
      description: `Look at your resume review report and select
“Improve Resume” to view the areas the resume
checker recommends fixing.`,
    },
    {
      image: Act3,
      title: "Optimize using our builder",
      description: `Use our resume builder to implement the suggested changes and enhance your resume score, making it more effective and impactful.`,
    },
    {
      image: Act4,
      title: "Ready to send",
      description: `Finalize your content and share it with HR for approval. They’ll ensure it aligns with company policies and standards`,
    },
  ];

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          minHeight: "calc(100vh - 140px)",
          bgcolor: palette.primary.main,
        }}
      >
        {/* Banner Section */}
        <Box sx={{ width: "100%", height: "100%" }}>
          <Banner src={BannerImage} alt="banner" />
        </Box>

        <Container maxWidth="xl" sx={{ padding: { xs: 2, md: 5 } }}>
          {/* Main Content Section */}
          <Stack
            direction={{ xs: "column", md: "row" }} // Responsive direction
            spacing={2}
            sx={{ position: "relative", mb: 5 }}
            justifyContent="space-between"
            alignItems="center"
          >
            {/* Left Image (Mag) */}
            <Box
              sx={{
                width: { xs: "150px", md: "250px" },
                position: "absolute",
                left: "-2%",
                bottom: { xs: "-20%", md: "-10%" },
              }}
            >
              <img src={Mag} alt="mag" width="100%" height="auto" />
            </Box>

            {/* Text Content */}
            <Stack
              direction="column"
              sx={{ width: { xs: "100%", md: "50%" }, mt: { xs: 10, md: 0 } }}
              spacing={5}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  width: { xs: "100%", md: "70%" },
                }}
              >
                <Box>
                  <Typography
                    variant="h1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: 28, md: 40 }, // Adjust font size for mobile
                      color: "#fff",
                    }}
                  >
                    ATS Resume Checker:
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#fff", fontSize: { xs: 16, md: 18 } }} // Adjust font size
                  >
                    Instantly Review, Score & Grade Your Resume
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography
                    sx={{ color: "#fff", fontSize: { xs: 16, md: 18 } }}
                  >
                    Optimize your resume in minutes
                  </Typography>
                  <Typography
                    sx={{ color: "#c2c2c2", fontSize: { xs: 14, md: 18 } }}
                  >
                    Our ATS resume grader will optimize your resume for any job,
                    score it, and provide suggestions for improvements to make
                    it as effective as possible.
                  </Typography>
                </Box>

                <Link to={"/ats/resume-checker"}>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#FF8873",
                      color: "#fff",
                      fontSize: 14,
                      height: 40,
                      width: { xs: "100%", md: 200 },
                      "&:hover": {
                        bgcolor: "#FF8892",
                      },
                    }}
                  >
                    Track Resume
                  </Button>
                </Link>
              </Box>
            </Stack>

            {/* Right Image (Resume) */}
            <Box
              sx={{
                width: { xs: "100%", md: "50%" }, // Responsive width
                mt: { xs: 5, md: 0 },
                display: "flex",
                justifyContent: { xs: "center", md: "center" },
              }}
            >
              <img src={ResumeImage} alt="resume" width="60%" />
            </Box>
          </Stack>

          {/* mid section */}
          <Stack direction={"row"} spacing={2} alignItems="center">
            <LeftImageBox src={LeftImage} alt="left-image" />

            <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
              {" "}
              {/* Center text on mobile */}
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: 18, md: 28 },
                  color: "#fff",
                }}
                component="h3"
                textAlign={{ xs: "center" }}
              >
                How Our Resume Checker Works
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: { xs: 14, md: 18 }, mt: 1, color: "#c2c2c2" }}
                textAlign={{ xs: "center" }}
                margin={"0 auto"}
                width={{ xs: "100%", md: "70%" }}
              >
                Our resume ATS checker will automatically scan your resume’s
                structure, grammar, spelling, and wording to provide actionable
                steps for improvement.
              </Typography>
            </Box>

            <RightImageBox src={RightImage} alt="right-image" />
          </Stack>

          {/* top bottom section */}
          <Stack>
            <Typography
              sx={{
                color: "#fff",
                fontSize: { xs: 24, md: 28 },
                textAlign: { xs: "center" },
                fontWeight: 700,
                my: { xs: 5, md: 10 },
              }}
            >
              Here’s how it works.
            </Typography>

            <Grid container spacing={3}>
              {GridJson.map((item, index) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        p: 2,
                      }}
                    >
                      <GridImage src={item?.image} alt={`image-${index}`} />
                      <Box
                        sx={{
                          bgcolor: "#fff",
                          p: 2,
                          width: "100%",
                          maxWidth: "400px",
                          height: "150px",
                          borderRadius: "10px",
                          mt: -0.2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: { xs: 18, md: 24 },
                            textAlign: "center",
                          }}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Stack>

          {/* mid bottom section */}

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 3, md: 5 }}
            alignItems="center"
            width={"90%"}
            mx={"auto"}
          >
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: 20, md: 28 },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: 700,
                  my: { xs: 2, md: 5 },
                }}
              >
                Why Should You Use a Resume Checker?
              </Typography>
              <Typography
                sx={{
                  color: "#c2c2c2",
                  fontSize: { xs: 14, md: 16 },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: 700,
                }}
              >
                Getting your resume right is no easy task. There’s so much to
                keep in mind! Hit the right keywords, properly showcase your
                achievements, add metrics to prove your value… And do all that
                while avoiding that random typo or a grammatical lapse.
              </Typography>
              <Typography
                sx={{
                  color: "#c2c2c2",
                  fontSize: { xs: 14, md: 16 },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: 700,
                }}
              >
                And the worst part? 6 in 10 recruiters will reject a resume if
                it contains any kind of error. Scary? Not anymore. Let our
                resume scanner help you.
              </Typography>
              <Typography
                sx={{
                  color: "#c2c2c2",
                  fontSize: { xs: 14, md: 16 },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: 700,
                }}
              >
                Our cutting-edge ATS resume checker uses an advanced algorithm
                to scan your resume for any grammatical, spelling, and
                punctuation mistakes. But that's not all. It also provides
                specific tips on which skills and keywords you should highlight
                to pass through resume screening software, along with guidance
                on phrasing your sentences for maximum impact.
              </Typography>
              <Typography
                sx={{
                  color: "#c2c2c2",
                  fontSize: { xs: 14, md: 16 },
                  textAlign: { xs: "center", md: "left" },
                  fontWeight: 700,
                }}
              >
                In essence, we'll handle all the proofreading and editing for
                you, enabling you to secure more job interviews and concentrate
                on what truly matters.
              </Typography>
            </Box>

            <Box sx={{ width: "100%" }}>
              {" "}
              {/* Adjust image size on mobile */}
              <ListsImage src={Lists} alt="lists-image" />
            </Box>
          </Stack>

          <Stack sx={{ width: { xs: "100%", md: "90%" } }} mx={"auto"} py={5}>
            {/* Section Heading */}
            <Box sx={{ textAlign: "center" }} p={2}>
              <Typography variant="h4" sx={{ color: "#fff", fontWeight: 700 }}>
                Key Features of the My Perfect Resume Builder
              </Typography>
            </Box>

            {/* Features Grid */}
            <Grid container width={"100%"} mx={"auto"} justifyContent="center">
              {[
                {
                  title: "Optimized to pass ATS",
                  description:
                    "Run your resume through our ATS resume checker to receive a resume score and detailed guidance on what to fix to ensure your resume gets past the ATS bots.",
                },
                {
                  title: "Tailored content suggestions",
                  description: (
                    <>
                      Input your target job title to receive tailored content
                      suggestions highlighting the hard and{" "}
                      <Typography
                        component="span"
                        sx={{ color: "#EFC778", textDecoration: "underline" }}
                      >
                        soft skills
                      </Typography>{" "}
                      recruiters seek.
                    </>
                  ),
                },
                {
                  title: "Professional and polished designs",
                  description: (
                    <>
                      Use any of our professionally designed and ATS-friendly{" "}
                      <Typography
                        component="span"
                        sx={{ color: "#EFC778", textDecoration: "underline" }}
                      >
                        resume templates
                      </Typography>{" "}
                      and get inspired with{" "}
                      <Typography
                        component="span"
                        sx={{ color: "#EFC778", textDecoration: "underline" }}
                      >
                        800+ resume examples
                      </Typography>
                      .
                    </>
                  ),
                },
                {
                  title: "Expert writing tips",
                  description:
                    "Get expert tips and content suggestions written by certified resume writers to ensure your resume has what it takes to compete with the top professionals in your industry.",
                },
              ].map((feature, index) => (
                <Grid item xs={12} md={6} key={index} p={2}>
                  <Box
                    p={3}
                    sx={{
                      background:
                        "linear-gradient(94.89deg,#13072ed9 1.2%,#3f2182d9 97.79%)",
                      borderRadius: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: 2,
                      border: "1px solid #c2c2c2",
                      height: { xs: "220px", md: "250px", lg: "180px" },
                    }}
                  >
                    {/* Feature Title */}
                    <Typography
                      variant="h6"
                      sx={{ color: "#fff", fontWeight: 600 }}
                    >
                      {feature.title}
                    </Typography>

                    {/* Feature Description */}
                    <Typography
                      sx={{
                        color: "#c2c2c2",
                        lineHeight: 1.6,
                        fontSize: { xs: 14, sm: 16 },
                      }}
                    >
                      {feature.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Ats;
