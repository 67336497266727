import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Container,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import Image from "../images/nav_logo.png";
import {
  Place as PlaceIcon,
  Call as CallIcon,
  Email as EmailIcon,
  LinkedIn as LinkedInIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  ChevronRight as ChevronRightIcon,
  Palette,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import Logo1 from "../images/Logo1.gif";
import Logo2 from "../images/Logo2.png";

const SpanIcons = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  flexWrap: "wrap",
});

const Link = styled("a")({
  textDecoration: "none",
  color: "#8890A0",
  "&:hover": {
    color: "#fff",
  },
});

const OurCourse = [
  {
    name: "Full Stack Developer Course",
    url: "https://unifiedmentor.com/web-development",
  },
  {
    name: "Data Science Course",
    url: "https://unifiedmentor.com/data-science",
  },
  {
    name: "Digital Marketing Course",
    url: "https://unifiedmentor.com/digital-marketing",
  },
  {
    name: "Machine Learning Course",
    url: "https://unifiedmentor.com/machine-learning",
  },
  // { name: "Data Analyst Course", url: "/data-analyst" },
  // { name: "UX/UI Designer Course", url: "/ui-ux-designer" },
];

const QuickLinks = [
  { name: "Blog", url: "https://blog.unifiedmentor.com/" },
  { name: "Services", url: "https://unifiedmentor.com/services" },
  { name: "Careers", url: "https://unifiedmentor.com/careers" },
  {
    name: "Privacy Policy",
    url: "https://unifiedmentor.com/privacy-policy",
  },
  {
    name: "Terms of Conditions",
    url: "https://unifiedmentor.com/terms-and-conditions",
  },
  // {
  //   name: "Internship Terms of Conditions",
  //   url: "/internship-terms-and-conditions",
  // },
  {
    name: "Cancellation and Refund Policy",
    url: "https://unifiedmentor.com/cancellation-and-refund",
  },
  {
    name: "Placement",
    url: "https://unifiedmentor.com/placement",
  },
  {
    name: "Shipping and Delivery",
    url: "https://unifiedmentor.com/shipping-and-delivery",
  },

  { name: "Contact Us", url: "https://unifiedmentor.com/contact-us" },
];

const socialLinks = [
  { icon: LinkedInIcon, url: "https://www.linkedin.com/company/unifiedmentor" },
  { icon: FacebookIcon, url: "https://www.facebook.com/Unifiedmentor" },
  { icon: TwitterIcon, url: "https://twitter.com/unifiedmentor" },
  { icon: InstagramIcon, url: "https://instagram.com/_unifiedmentor" },
  { icon: YouTubeIcon, url: "https://www.youtube.com/@_Unifiedmentor" },
];

const onlineCourse = [
  {
    name: "Data Science",
    url: "https://unifiedmentor.com/data-science",
  },
  {
    name: "Web development",
    url: "https://unifiedmentor.com/web-development",
  },
  {
    name: "Digital Marketing",
    url: "https://unifiedmentor.com/digital-marketing",
  },
  {
    name: "Machine learning",
    url: "https://unifiedmentor.com/machine-learning",
  },
  {
    name: "UX/UI Designer",
    // url: "/ui-ux-designer",
    url: "#",
  },
  {
    name: "Data Analyst",
    // url: "/data-analyst",
    url: "#",
  },
  {
    name: "Prompt Engineering",
    url: "#",
  },
  {
    name: "Blockchain",
    url: "#",
  },
  {
    name: "Python",
    url: "#",
  },
  {
    name: "Cyber Security",
    url: "#",
  },
  {
    name: "Project Management",
    url: "#",
  },
];

const Footer = () => {
  const { palette } = useTheme();
  const ImageGif = styled("img")({
    width: "4rem",
    height: "auto",
    objectFit: "contain",
  });
  const Image2 = styled("img")({
    width: "150px",
    height: "auto",
    objectFit: "contain",
  });
  return (
    <Box
      component="footer"
      sx={{
        // background:
        //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
        backgroundColor: "#0A001E",
        color: "white",
        py: 6,
      }}
    >
      <Container maxWidth="xl">
        <Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              mr: 5,
              p: 1,
            }}
          >
            <ImageGif src={Logo1} />
            <Image2 src={Logo2} />
          </Box>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Join thousands who have transformed their lives with our
            high-quality online Courses. Whether you're acquiring new skills,
            enhancing your career, or pursuing a passion, we have the perfect
            Course for you. Benefit from expert instructors, flexible learning
            options, and a supportive community. Start your journey to success
            today with our comprehensive and engaging programs.
          </Typography>
        </Box>
        <Grid container spacing={4}>
          {/* Courses Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Our Courses
            </Typography>
            <Grid container spacing={1}>
              {OurCourse.map((course) => (
                <Grid item xs={12} key={course.name}>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ChevronRightIcon />
                    <Link href={course.url}>{course.name}</Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Online Courses Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Online Courses
            </Typography>
            <Grid container spacing={1}>
              {onlineCourse.map((link) => (
                <Grid item xs={12} key={link.name}>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ChevronRightIcon />
                    <Link href={link.url}>{link.name}</Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Quick Links Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Quick Links
            </Typography>
            <Grid container spacing={1}>
              {QuickLinks.map((link) => (
                <Grid item xs={12} key={link.name}>
                  <Typography
                    variant="body2"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ChevronRightIcon />
                    <Link href={link.url}>{link.name}</Link>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 4 }} spacing={4}>
          {/* Contact Information */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Get In Touch
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <PlaceIcon sx={{ color: "#80ABDB" }} />
                <Typography variant="body2">
                  DLF Forum, Cybercity, Phase III, Gurugram, Haryana 122002
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <CallIcon sx={{ color: "#80ABDB" }} />
                <Typography variant="body2">+91 6283 800330</Typography>
              </Stack>
            </Box>
            <Box sx={{ mb: 2 }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <EmailIcon sx={{ color: "#80ABDB" }} />
                <Typography variant="body2">info@unifiedmentor.com</Typography>
              </Stack>
            </Box>
            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
              Follow Us
            </Typography>
            <Stack direction="row" spacing={1}>
              {socialLinks.map((link, index) => (
                <IconButton key={index} sx={{ color: "#80ABDB" }}>
                  <Link
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <link.icon />
                  </Link>
                </IconButton>
              ))}
            </Stack>
          </Grid>

          {/* Map Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "100%", // Adjust this value based on your design
                overflow: "hidden",
                borderRadius: 2, // Rounded corners
                boxShadow: 3, // Shadow effect
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.5374202223784!2d77.08581590490853!3d28.493474418181087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1f176bec9d11%3A0xacd51075ba4f4a4c!2sUnified%20Mentor!5e0!3m2!1sen!2sin!4v1722236519270!5m2!1sen!2sin"
                style={{
                  width: "400px",
                  height: "200px",
                  border: "0",
                  borderRadius: "inherit",
                  outline: "none",
                }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 10 }}>
          <Typography
            variant="body2"
            sx={{
              color: "#fff",
              textAlign: "left",
              mt: 8,
              fontSize: "18px",
            }}
          >
            © 2023-2024, Unified Mentor Pvt. Ltd.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
