import { toast } from "react-toastify";
import {
  CREATE_JOB_TYPE_FAIL,
  CREATE_JOB_TYPE_REQUEST,
  CREATE_JOB_TYPE_SUCCESS,
  DELETE_JOB_TYPE_FAIL,
  DELETE_JOB_TYPE_REQUEST,
  DELETE_JOB_TYPE_SUCCESS,
  EDIT_JOB_TYPE_FAIL,
  EDIT_JOB_TYPE_REQUEST,
  EDIT_JOB_TYPE_SUCCESS,
  JOB_TYPE_LOAD_FAIL,
  JOB_TYPE_LOAD_REQUEST,
  JOB_TYPE_LOAD_SUCCESS,
  SINGLE_LOAD_JOB_TYPE_FAIL,
  SINGLE_LOAD_JOB_TYPE_REQUEST,
  SINGLE_LOAD_JOB_TYPE_SUCCESS,
} from "../constants/jobTypeConstant";
import apiClient from "../../axiosConfig";

// load jobs type
export const jobTypeLoadAction = () => async (dispatch) => {
  dispatch({ type: JOB_TYPE_LOAD_REQUEST });
  try {
    const { data } = await apiClient.get("/api/type/jobs");
    // console.log("data", data);
    dispatch({
      type: JOB_TYPE_LOAD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOB_TYPE_LOAD_FAIL,
      payload: error.response.data.error,
    });
  }
};

// create jobs category
export const createJobTypeAction = (jobtype) => async (dispatch) => {
  dispatch({ type: CREATE_JOB_TYPE_REQUEST });

  try {
    const { data } = await apiClient.post("/api/type/create", jobtype);
    dispatch({
      type: CREATE_JOB_TYPE_SUCCESS,
      payload: data,
    });
    toast.success("Job type created successfully");
  } catch (error) {
    dispatch({
      type: CREATE_JOB_TYPE_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

export const editSingleJobTypeAction = (job_type) => async (dispatch) => {
  // console.log("edit job type", job_type);
  dispatch({ type: EDIT_JOB_TYPE_REQUEST });
  try {
    const { data } = await apiClient.put(
      `/api/type/update/${job_type._id}`,
      job_type
    );
    // console.log("data", data);
    dispatch({
      type: EDIT_JOB_TYPE_SUCCESS,
      payload: data,
    });
    toast.success("Category updated successfully");
  } catch (error) {
    dispatch({
      type: EDIT_JOB_TYPE_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};

// single Category action
export const typeLoadSingleAction = (id) => async (dispatch) => {
  dispatch({ type: SINGLE_LOAD_JOB_TYPE_REQUEST });
  try {
    const { data } = await apiClient.get(`/api/type/${id}`);
    dispatch({
      type: SINGLE_LOAD_JOB_TYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_LOAD_JOB_TYPE_FAIL,
      payload: error.response.data.error,
    });
  }
};

//delete category action
export const deleteSingleTypeAction = (type_id) => async (dispatch) => {
  dispatch({ type: DELETE_JOB_TYPE_REQUEST });
  try {
    const { data } = await apiClient.delete(`/api/type/delete/${type_id}`);
    dispatch({
      type: DELETE_JOB_TYPE_SUCCESS,
      payload: data,
    });
    toast.success("Skill deleted successfully");
  } catch (error) {
    dispatch({
      type: DELETE_JOB_TYPE_FAIL,
      payload: error.response.data.error,
    });
    toast.error(error.response.data.error);
  }
};
