import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import Header from "../component/Header";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  ListItemIcon,
  MenuItem,
  MenuList,
  Pagination,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { jobLoadAction } from "../redux/actions/jobAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../component/Footer";
import LoadingBox from "../component/LoadingBox";
import { jobTypeLoadAction } from "../redux/actions/jobTypeAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NewCardElement from "../component/NewCardElement";
import BluredCircle from "../images/BluredCircle.png";
import styled from "styled-components";

const ImageTag = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(5px)",
  top: "-10%",
  right: "0%",
  zIndex: "-1",
});
const ImageTag2 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(5px)",
  top: "50%",
  left: "30%",
  zIndex: "-1",
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const Home = () => {
  const { jobs, setUniqueLocation, pages, loading } = useSelector(
    (state) => state.loadJobs
  );
  const { userInfo } = useSelector((state) => state.signIn);

  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keyword, location } = useParams();

  const [page, setPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const { jobType } = useSelector((state) => state.jobTypeAll);
  const [activeJobType, setActiveJobType] = useState("");

  useEffect(() => {
    dispatch(jobTypeLoadAction());
  }, [dispatch]);

  const handleShowMore = () => {
    if (userInfo) {
      setShowAll(true);
    } else {
      navigate("/login");
    }
  };

  const jobsToDisplay = showAll ? jobs : jobs ? jobs.slice(0, 4) : [];

  // console.log(jobs, "jobs");

  const handleJobTypeChange = (jt) => {
    setActiveJobType(jt);
  };

  useEffect(() => {
    dispatch(jobLoadAction(page, keyword, activeJobType, location));
  }, [dispatch, page, keyword, activeJobType, location]);

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          // background:
          //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
          backgroundColor: palette.primary.main,
        }}
      >
        <Navbar />
        <Header />
        <Container maxWidth="xl">
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Box
              sx={{
                flex: 2,
                p: { xs: 1, sm: 2 },
                position: "relative",
                zIndex: 99,
              }}
            >
              {/* <Card
                sx={{
                  minWidth: 150,
                  mb: 3,
                  mt: 3,
                  p: 2,
                  bgcolor: palette.primary.white,
                }}
              >
                <Box sx={{ pb: 2 }}>
                  <Typography
                    component="h4"
                    sx={{ color: palette.secondary.main, fontWeight: 600 }}
                  >
                    Filter job by category
                  </Typography>
                </Box>
                <SelectComponent
                  handleChangeCategory={handleChangeCategory}
                  cat={cat}
                />
              </Card> */}

              <Card
                sx={{
                  minWidth: 150,
                  mb: 3,
                  mt: 3,
                  p: 2,
                  bgcolor: palette.primary.white,
                }}
              >
                <Box sx={{ pb: 2 }}>
                  <Typography
                    component="h4"
                    sx={{ color: palette.secondary.main, fontWeight: 600 }}
                  >
                    Filter job by location
                  </Typography>
                  <MenuList>
                    {setUniqueLocation &&
                      setUniqueLocation.map((location, i) => (
                        <MenuItem key={i}>
                          <ListItemIcon>
                            <LocationOnIcon
                              sx={{
                                color: palette.secondary.main,
                                fontSize: 18,
                              }}
                            />
                          </ListItemIcon>
                          <Link
                            style={{ color: palette.secondary.main }}
                            to={`/search/location/${location}`}
                          >
                            {location}
                          </Link>
                        </MenuItem>
                      ))}
                  </MenuList>
                </Box>
              </Card>
              <ImageTag2 src={BluredCircle} alt="blured circle" />
            </Box>
            <Box
              sx={{
                flex: 5,
                p: { xs: 1, sm: 2 },
                position: "relative",
                zIndex: 10,
              }}
            >
              <ImageTag src={BluredCircle} alt="blured circle" />
              <Box
                variant="div"
                sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}
              >
                <Chip
                  label="All"
                  onClick={() => handleJobTypeChange("")}
                  sx={{
                    m: 1,
                    bgcolor: `${activeJobType === "" ? "#3C1F7D" : "inherit"}`,
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    color: `#fafafa`,
                    fontWeight: 600,
                    "&:hover": {
                      backgroundColor: "#3C1F7D",
                      border: "1px solid #e0e0e0",
                      color: "white",
                      cursor: "pointer",
                    },
                  }}
                />
                {jobType?.map((jt) => (
                  <Chip
                    key={jt._id}
                    label={jt.jobTypeName}
                    onClick={() => handleJobTypeChange(jt._id)}
                    sx={{
                      m: 1,
                      bgcolor: `${
                        activeJobType === jt._id ? "#3C1F7D" : "inherit"
                      }`,
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      color: "#fafafa",
                      fontWeight: 600,
                      "&:hover": {
                        bgcolor: "#3C1F7D",
                        border: "1px solid #e0e0e0",
                        color: "white",
                        cursor: "pointer",
                      },
                    }}
                  />
                ))}
              </Box>
              {loading ? (
                <LoadingBox />
              ) : jobs && jobs.length === 0 ? (
                <Box
                  sx={{
                    minHeight: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">No result found!</Typography>
                </Box>
              ) : (
                <Grid container rowGap={4} sx={{ py: 5 }} width={"100%"}>
                  {jobsToDisplay?.map((job, i) => (
                    <Grid item key={i} mx={"auto"}>
                      <NewCardElement
                        id={job._id}
                        companyName={job?.companyName}
                        companyLogo={job?.companyLogo}
                        jobTitle={job.title}
                        jobSalary={job.salary}
                        description={job.description}
                        category={
                          job.jobType ? job.jobType.jobTypeName : "No category"
                        }
                        experience={job?.experience}
                        experienceYears={job?.experienceYears}
                        skills={job?.skills}
                        deadline={job?.deadline}
                        location={job.location}
                      />
                    </Grid>
                  ))}

                  {!showAll && jobs?.length > 3 && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mt: 2,
                        }}
                      >
                        <Button
                          sx={{
                            color: "#fff",
                            border: "1px solid #fff",
                            backgroundColor: "inherit",
                            "&:hover": {
                              color: "#fff",
                              backgroundColor: "#3C1F7D",
                            },
                          }}
                          onClick={handleShowMore}
                        >
                          Show More
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              <Stack spacing={2} py={5}>
                <Pagination
                  variant="outlined"
                  page={page}
                  count={pages === 0 ? 1 : pages}
                  onChange={(event, value) => setPage(value)}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    color: "#fff",
                    "& .MuiPaginationItem-root": {
                      color: "#fff",
                      borderColor: "#fff",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#1976d2",
                      borderColor: "#fff",
                      color: "#fff",
                    },
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Home;

// {/* <CardElement
//                           key={i}
//                           id={job._id}
//                           companyName={job?.companyName}
//                           companyLogo={job?.companyLogo}
//                           jobTitle={job.title}
//                           jobSalary={job.salary}
//                           description={job.description}
//                           category={
//                             job.jobType ? job.jobType.jobTypeName : "No category"
//                           }
//                           experience={job?.experience}
//                           experienceYears={job?.experienceYears}
//                           skills={job?.skills}
//                           deadline={job?.deadline}
//                           location={job.location}
//                         /> */}
