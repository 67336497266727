import {
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import { useTheme } from "@emotion/react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BluredCircle from "../images/BluredCircle.png";

const Banner = styled("div")({
  width: "100%",
  height: "400px", // Default height for larger screens
  maxHeight: "100%",
  borderRadius: "20px",
  background: "linear-gradient(94.89deg,#13072ed9 1.2%,#3f2182d9 97.79%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  padding: 10,

  // Media queries for responsiveness

  "@media (max-width: 900px)": {
    height: "100%",
    borderRadius: "5px",
  },
});

const ClipTool = styled("div")({
  clipPath:
    "polygon(25.1% 0%, 62.7% 0%, 62.9% 49.8%, 63.1% 100.3%, 43.8% 71%, 25.1% 100.3%)",
  width: "450px",
  height: "300px",
  background:
    "linear-gradient(0deg, rgba(60,31,124,1) 0%, rgba(109,57,226,1) 100%)",
  backgroundColor: "rgb(60,31,124)",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "0",
  right: "-5rem",
  "@media (max-width: 1050px)": {
    display: "none",
  },
});

const TextContainer = styled("div")({
  display: "flex",
  minWidth: "350px",
  width: "100%",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: ".5rem",
});
const HeadTypo = styled("div")({
  color: "white",
  width: "20%",
  "@media (max-width: 1050px)": {
    width: "30%",
  },
  fontWeight: "bold",
  fontSize: "1.2rem",
});

const CircleImage2 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(10px)",
  bottom: "0",
  right: "0%",

  "@media (max-width: 600px)": {
    display: "none",
  },
});

const CircleImage1 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(10px)",
  top: "0",
  left: "-15%",

  "@media (max-width: 600px)": {
    display: "none",
  },
});

const HeadTypoText = styled("div")({
  color: "white",
  width: "60%",
  fontSize: "1rem",
});

function CircularProgressWithLabel(props) {
  const isLessThan50 = Number(props.value) < 50;
  const color = isLessThan50 ? "red" : "green";
  const textValue = () => {
    if (Number(props.value) < 50) {
      return `Weak`;
    } else if (Number(props.value) >= 50 && Number(props.value) < 60) {
      return `Average`;
    } else if (Number(props.value) >= 60 && Number(props.value) < 80) {
      return `Good`;
    } else {
      return `Strong`;
    }
  };
  const size = 250; // Size in pixels
  const responsiveSize = 200; // Size in pixels

  const mediaQueryforsmallScreen = useMediaQuery("(max-width: 450px)");
  // console.log(mediaQueryforsmallScreen);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: size,
        height: size,

        "@media (max-width: 450px)": {
          height: responsiveSize,
          width: responsiveSize,
        },
      }}
    >
      {/* White background circle */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={mediaQueryforsmallScreen ? responsiveSize : size}
        thickness={4}
        sx={{
          color: "white",
          position: "absolute",
        }}
      />
      {/* Colored progress circle */}
      <CircularProgress
        variant="determinate"
        {...props}
        size={mediaQueryforsmallScreen ? responsiveSize : size}
        thickness={4}
        sx={{
          color: color,
          position: "absolute",
          "& .MuiCircularProgress-circle": {
            strokeLinecap: "round",
          },
        }}
      />
      {/* Percentage text */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{ color: color, fontWeight: "bold" }}
          textAlign={"center"}
          fontSize={mediaQueryforsmallScreen ? 32 : 42}
        >
          {`${Math.round(props.value)}%`}
          <br />
          {textValue()}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function greetingByTime() {
  // Get the current hour
  const currentHour = new Date().getHours();

  // Define the time ranges for each greeting
  const morningStart = 5;
  const afternoonStart = 12;
  const eveningStart = 18;

  // Determine the appropriate greeting based on the current hour
  if (currentHour >= morningStart && currentHour < afternoonStart) {
    return "Good <br /> Morning";
  } else if (currentHour >= afternoonStart && currentHour < eveningStart) {
    return "Good <br /> Afternoon";
  } else {
    return "Good <br /> Evening";
  }
}

const SideBar = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
  background:
    "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
  backgroundColor: "rgb(20,8,49)",
  width: "25%",
  height: "100%",
  borderRadius: "20px",
  border: "1px solid white",
  overflowY: "auto",

  /* Custom scrollbar styles */
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "80%", // Set the width of the scrollbar
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "rgba(41,21,89,0.5)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(61,32,126,1)",
    borderRadius: "10px",
    border: "2px solid rgba(20,8,49,1)",
    cursor: "pointer",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(61,32,126,0.8)",
  },
});
const RightSide = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
  background:
    "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
  backgroundColor: "rgb(20,8,49)",
  width: "75%",
  height: "100%",
  borderRadius: "20px",
  border: "1px solid white",
  overflowY: "auto",
  position: "relative",
  zIndex: 9,

  "&::-webkit-scrollbar": {
    display: "none",
  },

  // /* Custom scrollbar styles */
  // "&::-webkit-scrollbar": {
  //   width: "2px",
  //   height: "80%", // Set the width of the scrollbar
  // },
  // "&::-webkit-scrollbar-track": {
  //   backgroundColor: "rgba(41,21,89,0.5)", // Track color
  //   borderRadius: "10px", // Track radius
  // },
  // "&::-webkit-scrollbar-thumb": {
  //   backgroundColor: "rgba(61,32,126,1)", // Thumb color (scroll handle)
  //   borderRadius: "10px", // Rounded edges
  //   border: "2px solid rgba(20,8,49,1)", // Adding a border for separation effect
  //   cursor: "pointer",
  // },
  // "&::-webkit-scrollbar-thumb:hover": {
  //   backgroundColor: "rgba(61,32,126,0.8)", // Darken on hover for effect
  // },
});

const SideButton = styled("div")({
  width: "90%",
  margin: "0 auto",
  color: "white",
  cursor: "pointer",
  textAlign: "center",
  padding: "10px 20px",
  fontSize: "1.2rem",
  fontWeight: "bold",
  transition: "background-color 0.3s",
  borderRadius: "10px",
  position: "relative", // Make the parent position relative
  "&:hover": {
    background: "rgb(20,8,70)",
    border: "1px solid white",
  },

  // Line at the bottom
  "&::after": {
    content: '""',
    width: "100%",
    height: "1px",
    display: "block",
    backgroundColor: "white",
    position: "absolute",
    bottom: "-5px",
    left: "0",
  },
});

const DetailResume = ({ finalAtsResult, setFinalAtsResult }) => {
  // console.log("DetailResume - finalAtsResult:", finalAtsResult);
  const { palette } = useTheme();
  const [active, setActive] = useState("Missing Keywords");
  const [data, setData] = useState({});

  useEffect(() => {
    // console.log("First useEffect is running");
    try {
      const storedData = localStorage.getItem("response");
      // console.log("Data in localStorage:", storedData);

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        // console.log("Parsed data from localStorage:", parsedData);

        if (
          parsedData &&
          typeof parsedData === "object" &&
          Object.keys(parsedData).length > 0
        ) {
          // console.log("Setting data from localStorage");
          setData(parsedData);

          if (!finalAtsResult || Object.keys(finalAtsResult).length === 0) {
            // console.log(
            //   "finalAtsResult is empty, updating with localStorage data"
            // );
            setFinalAtsResult(parsedData);
          } else {
            // console.log("finalAtsResult already has data:", finalAtsResult);
          }
        } else {
          // console.log("Parsed data from localStorage is empty or invalid");
        }
      } else {
        // console.log("No data in localStorage");
        if (finalAtsResult && Object.keys(finalAtsResult).length > 0) {
          // console.log("Using finalAtsResult:", finalAtsResult);
          setData(finalAtsResult);
          localStorage.setItem("response", JSON.stringify(finalAtsResult));
        } else {
          // console.log("No data found in localStorage or finalAtsResult");
        }
      }
    } catch (error) {
      console.error("Error in initial data loading:", error);
    }
  }, []);

  useEffect(() => {
    // console.log("Second useEffect is running, finalAtsResult:", finalAtsResult);
    if (
      finalAtsResult &&
      typeof finalAtsResult === "object" &&
      Object.keys(finalAtsResult).length > 0
    ) {
      // console.log("Updating localStorage with finalAtsResult:", finalAtsResult);
      try {
        localStorage.setItem("response", JSON.stringify(finalAtsResult));
        setData(finalAtsResult);
      } catch (error) {
        console.error("Error updating localStorage:", error);
      }
    } else {
      // console.log(
      //   "finalAtsResult is empty or invalid, not updating localStorage"
      // );
    }
  }, [finalAtsResult]);

  useEffect(() => {
    // console.log("Third useEffect is running, data:", data);
  }, [data]);

  const tabletScreen = useMediaQuery("(min-width: 900px)");
  // console.log("tabletScreen", tabletScreen);

  function getResumeData(response) {
    if (!response) {
      console.error("Response is undefined or null");
      return null;
    }

    return {
      getJobDescriptionMatch: () => response["Job Description Match"],
      getMissingKeywords: () => response["Missing Keywords"],
      getMissingSkills: () => response["Missing Skills"],
      getMissingSections: () => response["Missing Sections"],
      getImprovementsSummary: () => response["Improvements Summary"],
      getDesignImprovement: () => response["Design Improvement"],
      getName: () => response["Name"],
      getPhoneNumber: () => response["Phone Number"],
      getEmailAddress: () => response["Email Address"],
      getLinkedInURL: () => response["LinkedIn URL"],
      getEducation: () => response["Education"],
      getWorkHistory: () => response["Work History"],
      getSkillsAndAchievements: () => response["Skills / Achievements"],
      getDateFormatting: () => response["Date Formatting"],
      getLexicalAnalysis: () => response["Lexical Analysis"],
      getSemanticAnalysis: () => response["Semantic Analysis"],
    };
  }

  const response = data?.analysis ? getResumeData(data?.analysis) : null;

  const JobDescriptionMatchData = response?.getJobDescriptionMatch();
  const MissingKeywordsData = response?.getMissingKeywords();
  const MissingSkillsData = response?.getMissingSkills();
  const MissingSectionsData = response?.getMissingSections();
  const ImprovementsSummaryData = response?.getImprovementsSummary();
  const DesignImprovementData = response?.getDesignImprovement();
  const NameData = response?.getName();
  const PhoneNumberData = response?.getPhoneNumber();
  const EmailAddressData = response?.getEmailAddress();
  const LinkedInURLData = response?.getLinkedInURL();
  const EducationData = response?.getEducation();
  const WorkHistoryData = response?.getWorkHistory();
  const SkillsData = response?.getSkillsAndAchievements();
  const DateFormattingData = response?.getDateFormatting();
  const LexicalAnalysisData = response?.getLexicalAnalysis();
  const SemanticAnalysisData = response?.getSemanticAnalysis();

  const returnFlatObj = (data) => {
    if (!data || typeof data !== "object") {
      // console.log("Invalid data provided to returnFlatObj:", data);
      return [];
    }

    const filteredData = Object.keys(data).flatMap((key) => {
      const value = data[key];
      if (value) {
        return { key, value };
      }
      return [];
    });

    return filteredData;
  };

  // console.log(
  //   "returnFlatObj(LexicalAnalysisData)",
  //   returnFlatObj(LexicalAnalysisData)
  // );

  const renderContent = () => {
    try {
      switch (active) {
        case "Missing Keywords":
          return <MissingKeywords MissingKeywordsData={MissingKeywordsData} />;
        case "Missing Sections":
          return <MissingSections MissingSectionsData={MissingSectionsData} />;
        case "Work History":
          return <WorkHistory WorkHistoryData={WorkHistoryData} />;
        case "Skills / Achievements":
          return (
            <Skills
              SkillsData={SkillsData}
              MissingSkillsData={MissingSkillsData}
            />
          );
        case "Date Formatting":
          return <DateFormatting DateFormattingData={DateFormattingData} />;
        case "Design Improvement":
          return (
            <DesignImprovement DesignImprovementData={DesignImprovementData} />
          );
        case "Lexical Analysis":
          return (
            <LexicalAnalysis
              LexicalAnalysisData={returnFlatObj(LexicalAnalysisData)}
            />
          );
        case "Semantic Analysis":
          return (
            <SemanticAnalysis
              SemanticAnalysisData={returnFlatObj(SemanticAnalysisData)}
            />
          );
        case "Improvements Summary":
          return (
            <ImprovementsSummary
              ImprovementsSummaryData={ImprovementsSummaryData}
              EducationData={EducationData}
            />
          );
        default:
          return <MissingKeywords MissingKeywordsData={MissingKeywordsData} />;
      }
    } catch (error) {
      console.error("Error rendering content:", error);
      return <Typography>Error loading content</Typography>;
    }
  };

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          minHeight: "calc(100vh - 140px)",
          height: "100%",
          bgcolor: palette.primary.main,
        }}
      >
        <Container
          maxWidth="xl"
          sx={{ background: "inherit", pt: 2, position: "relative" }}
        >
          <CircleImage1 src={BluredCircle} alt="circle" />
          <CircleImage2 src={BluredCircle} alt="circle" />
          <Banner>
            <Stack
              direction="row"
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap="wrap"
              // Allow wrapping on smaller screens
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: { xs: 3, sm: 0 },
                  width: { xs: "100%", sm: "45%" }, // Responsive width
                }}
              >
                <CircularProgressWithLabel
                  value={JobDescriptionMatchData?.split("%")[0] || 0}
                />
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "55%" }, // Responsive width
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <TextContainer>
                  <HeadTypo>Name:</HeadTypo>
                  <HeadTypoText>{NameData || "Not Available"}</HeadTypoText>
                </TextContainer>
                <TextContainer>
                  <HeadTypo>Email Address:</HeadTypo>
                  <HeadTypoText>
                    {EmailAddressData || "Not Available"}
                  </HeadTypoText>
                </TextContainer>
                <TextContainer>
                  <HeadTypo>Phone Number:</HeadTypo>
                  <HeadTypoText>
                    {PhoneNumberData || "Not Available"}
                  </HeadTypoText>
                </TextContainer>
                <TextContainer>
                  <HeadTypo>LinkedIn URL:</HeadTypo>
                  <HeadTypoText>
                    {LinkedInURLData || "Please Provide Your LinkedIn URL!"}
                  </HeadTypoText>
                </TextContainer>
              </Box>
            </Stack>

            <ClipTool>
              <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#fff",
                    position: "absolute",
                    bottom: "50%",
                    left:
                      greetingByTime() === "Good <br /> Afternoon"
                        ? "26.5%"
                        : greetingByTime() === "Good <br /> Evening"
                        ? "30%"
                        : "30%",
                    textAlign: "center",
                    fontSize: "2.2rem",
                    fontWeight: 700,
                  }}
                  dangerouslySetInnerHTML={{ __html: greetingByTime() }}
                />
              </Box>
            </ClipTool>
          </Banner>
          {/* <Box
            sx={{
              width: "100%",
              maxHeight: "100%",
              height: "48rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              py: 10,
            }}
          >
            <SideBar>
              <SideButton onClick={() => setActive("Missing Keywords")}>
                Missing Keywords
              </SideButton>
              <SideButton onClick={() => setActive("Missing Sections")}>
                Missing Sections
              </SideButton>
              <SideButton onClick={() => setActive("Work History")}>
                Work History
              </SideButton>
              <SideButton onClick={() => setActive("Skills / Achievements")}>
                Skills / Achievements
              </SideButton>
              <SideButton onClick={() => setActive("Date Formatting")}>
                Date Formatting
              </SideButton>
              <SideButton onClick={() => setActive("Design Improvement")}>
                Design Improvement
              </SideButton>
              <SideButton onClick={() => setActive("Lexical Analysis")}>
                Lexical Analysis
              </SideButton>
              <SideButton onClick={() => setActive("Semantic Analysis")}>
                Semantic Analysis
              </SideButton>
              <SideButton onClick={() => setActive("Improvements Summary")}>
                Improvements Summary
              </SideButton>
            </SideBar>
            <RightSide>{renderContent()}</RightSide>
          </Box> */}
          {tabletScreen ? (
            <Box
              sx={{
                width: "100%",
                height: "48rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                pt: 10,
              }}
            >
              <SideBar>
                {[
                  "Missing Keywords",
                  "Missing Sections",
                  "Work History",
                  "Skills / Achievements",
                  "Date Formatting",
                  "Design Improvement",
                  "Lexical Analysis",
                  "Semantic Analysis",
                  "Improvements Summary",
                ].map((item) => (
                  <SideButton
                    style={{
                      backgroundColor: `${
                        active === item ? "rgb(20,8,70)" : "transparent"
                      }`,
                      border: `${active === item ? "1px solid white" : "none"}`,
                    }}
                    key={item}
                    onClick={() => setActive(item)}
                  >
                    {item}
                  </SideButton>
                ))}
              </SideBar>
              <RightSide>{renderContent()}</RightSide>
            </Box>
          ) : (
            <Box>
              <Stack direction="column" py={5}>
                <MissingKeywordsMobile
                  MissingKeywordsData={MissingKeywordsData}
                />
                <MissingSectionsMobile
                  MissingSectionsData={MissingSectionsData}
                />
                <WorkHistoryMobile WorkHistoryData={WorkHistoryData} />
                <SkillsMobile
                  SkillsData={SkillsData}
                  MissingSkillsData={MissingSkillsData}
                />
                <DateFormattingMobile DateFormattingData={DateFormattingData} />
                <DesignImprovementMobile
                  DesignImprovementData={DesignImprovementData}
                />
                <LexicalAnalysisMobile
                  LexicalAnalysisData={returnFlatObj(LexicalAnalysisData)}
                />
                <SemanticAnalysisMobile
                  SemanticAnalysisData={returnFlatObj(SemanticAnalysisData)}
                />
                <ImprovementsSummaryMobile
                  ImprovementsSummaryData={ImprovementsSummaryData}
                  EducationData={EducationData}
                />
              </Stack>
            </Box>
          )}

          <Box
            sx={{
              display: "grid",
              placeContent: "center",
              py: 5,
            }}
          >
            <Button
              onClick={() => window.history.back()}
              sx={{
                bgcolor: "#fff",
                border: "1px solid #fff",
                color: "#000",
                borderRadius: "25px",
                width: "200px",
                "&:hover": {
                  bgcolor: "#13072E",
                  color: "#fff",
                },
              }}
            >
              Finish
            </Button>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

// for desktop view only
const MissingKeywords = ({ MissingKeywordsData }) => {
  function setData() {
    if (!MissingKeywordsData || MissingKeywordsData === "N/A") {
      return "Your resume already includes all the necessary industry-specific keywords, which ensures strong alignment with the job description. This increases the likelihood of your resume performing well in applicant tracking systems (ATS) and making a positive impression on hiring managers.";
    } else {
      return MissingKeywordsData;
    }
  }
  // console.log("MissingKeywordsData", MissingKeywordsData);
  return (
    <Stack direction="column" spacing={5} pt={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Missing Keywords
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        To improve content relevance and searchability, first identify crucial
        industry-specific keywords and concepts that are currently missing.
        Conduct a thorough analysis of the text to pinpoint gaps in terminology.
        Then, strategically insert these key terms to enhance the content's
        relevance and boost its search engine optimization (SEO). Ensure that
        the keywords are seamlessly integrated into the content without
        disrupting readability. This approach will help attract a more targeted
        audience and improve the overall effectiveness of the content in search
        results.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setData()}
        </Typography>
      </Box>
    </Stack>
  );
};

// for desktop view only
const MissingSections = ({ MissingSectionsData }) => {
  function setData() {
    if (!MissingSectionsData || MissingSectionsData === "N/A") {
      return "If you want to achieve a perfect score, you don’t need to add any additional sections to your resume at this time. The structure and sections you’ve included are already well-organized and comprehensive. Focus on enhancing the content within the existing sections for an even stronger alignment with the job requirements.";
    } else {
      return MissingSectionsData;
    }
  }

  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Missing Sections
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Review the content to confirm it covers all essential sections and
        provides a comprehensive overview of the subject. Identify any missing
        key topics, critical details, or important elements that may be
        necessary for a complete and coherent presentation. Integrate these
        components to ensure the content is thorough and addresses all relevant
        aspects. Make sure the added information blends seamlessly with the
        existing material, preserving a logical flow and enhancing the overall
        clarity and effectiveness. This approach will ensure the content is
        well-rounded and fully informative.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setData()}
        </Typography>
      </Box>
    </Stack>
  );
};
const WorkHistory = ({ WorkHistoryData }) => {
  function setData() {
    if (!WorkHistoryData || WorkHistoryData === "N/A") {
      return "Your resume currently lacks a work history section. Including relevant professional experience, internships, or project work will significantly strengthen your application. Even if you don't have formal work experience, consider adding volunteer work, freelance projects, or academic projects to showcase your skills and accomplishments.";
    } else {
      return WorkHistoryData;
    }
  }
  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Work History
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Update or refine the work history section to accurately reflect job
        roles, responsibilities, and achievements. Clearly articulate key
        contributions, skills acquired, and the impact made in each position.
        Ensure that each entry highlights specific accomplishments and
        quantifiable results where possible, providing a detailed account of how
        you added value to the organization. Revise descriptions to showcase
        relevant expertise and effectively communicate your career progression.
        This approach will enhance the clarity and effectiveness of your work
        history, making it more compelling to potential employers.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Your Work History:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setData()}
        </Typography>
      </Box>
    </Stack>
  );
};
const Skills = ({ SkillsData, MissingSkillsData }) => {
  function setSkillsData() {
    if (!SkillsData || SkillsData === "N/A") {
      return "It looks like your resume is missing a skills section, which is a critical component for any job application. Highlighting your technical and functional skills is essential to demonstrate your qualifications for the role. I recommend adding a list of key skills relevant to the position to improve your chances of standing out to hiring managers.";
    } else {
      return SkillsData;
    }
  }
  function setMissingSkillsData() {
    if (!MissingSkillsData || MissingSkillsData === "N/A") {
      return "Great job! Your resume showcases all the relevant skills required for this role, which is a strong indicator of your qualifications. Keep up the good work, and continue honing your expertise and learning new skills to stay ahead in your career and future opportunities.";
    } else {
      return MissingSkillsData;
    }
  }

  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Skills / Achievements
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Highlight relevant skills and achievements, ensuring they align with the
        job or field you’re targeting. Emphasize how these skills contributed to
        your success and address industry-specific requirements and
        expectations. Focus on showcasing how your expertise directly impacted
        past roles, including any quantifiable results or notable
        accomplishments. Tailor your descriptions to demonstrate your
        proficiency in key areas and how you’ve effectively applied these skills
        to meet challenges and drive success. This targeted approach will
        strengthen your profile and align it with industry standards.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setMissingSkillsData()}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Your Skills:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setSkillsData()}
        </Typography>
      </Box>
    </Stack>
  );
};
const DateFormatting = ({ DateFormattingData }) => {
  function setData() {
    if (!DateFormattingData || DateFormattingData === "N/A") {
      return "Your resume's date formatting is consistent and clear. No further adjustments are necessary in this area, as it meets the standard expectations for professional presentation.";
    } else {
      return DateFormattingData;
    }
  }

  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Date Formatting
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Ensure consistency and accuracy in date formatting throughout the
        document. Choose a uniform style for all dates, such as "Month Year" or
        "MM/YYYY," and apply it consistently to maintain professionalism and
        readability. This approach helps avoid confusion or errors and ensures
        that your document appears polished and well-organized. Review the
        entire document carefully to correct any inconsistencies or formatting
        discrepancies. Adhering to a single date format will enhance the clarity
        and overall presentation of your content.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setData()}
        </Typography>
      </Box>
    </Stack>
  );
};
const DesignImprovement = ({ DesignImprovementData }) => {
  function setData() {
    if (!DesignImprovementData || DesignImprovementData === "N/A") {
      return "Your resume’s design is already effective and well-organized. It presents information clearly and professionally, making it easy for hiring managers to read. Maintaining a clean and structured layout will continue to serve you well. However, always be open to revisiting your resume design periodically to ensure it remains modern and visually appealing.";
    } else {
      return DesignImprovementData;
    }
  }
  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Design Improvement
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Enhance the document's design to improve readability and visual appeal
        by refining the layout and typography. Use clear, descriptive headings
        and ensure consistent font choices throughout. Apply appropriate spacing
        to separate sections and improve overall flow. Aim for a balanced
        structure that guides the reader's eye and makes the content engaging
        and easy to navigate. Adjust margins, line spacing, and font sizes as
        needed to create a polished, professional appearance. These design
        improvements will make the document more accessible and visually
        appealing.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {setData()}
        </Typography>
      </Box>
    </Stack>
  );
};
const LexicalAnalysis = ({ LexicalAnalysisData }) => {
  // console.log(LexicalAnalysisData);

  function setData() {
    if (!LexicalAnalysisData.length || LexicalAnalysisData === "N/A") {
      return `Personal Pronouns: Overuse of personal pronouns may make your resume seem informal. Consider focusing on achievements rather than self-references.
Numericized Data: Your resume lacks sufficient numeric data to back up your achievements. Including numbers can help quantify your impact.
Vocabulary Level: The vocabulary level is too basic, which might not reflect a strong professional image. Consider using more precise and varied terms.
Reading Level: The reading level is too low, making the content seem overly simplistic. Adjust to a more professional tone.
Common Words: Overuse of common words like 'the', 'and', 'of'. Try reducing redundancy to make your resume more engaging and impactful.`;
    }
  }
  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Lexical Analysis
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Analyze and refine word choice to ensure clarity and appropriateness for
        your target audience. Use precise, accessible language that effectively
        conveys key messages. Adjust tone and terminology to align with the
        audience’s needs and expectations, making sure the content resonates
        with them. Avoid jargon or complex vocabulary unless necessary and
        appropriate. By tailoring language to the audience, you enhance
        understanding and engagement, ensuring that the core messages are
        communicated clearly and effectively. This approach will make the
        content more relevant and impactful.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Box>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
              color: "#fff",
              fontSize: "18px",
              mt: 2,
            }}
          >
            {LexicalAnalysisData?.length
              ? LexicalAnalysisData?.map((item) => (
                  <ListItem sx={{ display: "list-item" }} key={item.key}>
                    {item.key}: {item?.value}
                  </ListItem>
                ))
              : setData()}
          </List>
        </Box>
      </Box>
    </Stack>
  );
};
const SemanticAnalysis = ({ SemanticAnalysisData }) => {
  function setData() {
    if (!SemanticAnalysisData.length || SemanticAnalysisData === "N/A") {
      return `Clarity: Your resume lacks clarity in presenting your qualifications. The wording could be more concise and focused.
    Relevance: Some sections of your resume include irrelevant information that does not align with the job you're applying for. Make sure to tailor your content for each position.
    Consistency: There are inconsistencies in your language and tone, which might confuse hiring managers. Ensure that your resume is uniform and professional throughout.
    Tone: The tone of your resume is too informal or casual. A more professional tone will give a better impression to potential employers.
    Keywords: Your resume does not include enough industry-specific keywords, which could make it harder for Applicant Tracking Systems (ATS) to recognize your skills. Adding relevant keywords will improve your chances.`;
    }
  }

  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Semantic Analysis
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Review and refine the content to ensure accuracy in meaning and context.
        Verify that all information is correct and aligns with the intended
        message. Make sure the ideas are communicated clearly and effectively,
        addressing the target audience's needs and expectations. Adjust wording
        and phrasing as necessary to enhance clarity and coherence, ensuring
        that the content accurately reflects the intended ideas and avoids any
        misunderstandings. This careful refinement will ensure that the message
        is conveyed precisely and resonates well with the audience.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Box>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
              color: "#fff",
              fontSize: "18px",
            }}
          >
            {SemanticAnalysisData?.length
              ? SemanticAnalysisData?.map((item) => (
                  <ListItem sx={{ display: "list-item" }} key={item.key}>
                    {item.key}: {item?.value}
                  </ListItem>
                ))
              : setData()}
          </List>
        </Box>
      </Box>
    </Stack>
  );
};
const ImprovementsSummary = ({ ImprovementsSummaryData, EducationData }) => {
  function ImprovementsSummaryDatas() {
    if (!ImprovementsSummaryData || ImprovementsSummaryData === "N/A") {
      return "Your resume is well-crafted and does not require an improvement summary at this time. The information you’ve provided is already strong and effectively showcases your qualifications and achievements. Keep up the good work, and continue to update your resume with any new accomplishments or skills as you progress in your career.";
    } else {
      return ImprovementsSummaryData;
    }
  }
  function EducationDatas() {
    if (!EducationData || EducationData === "N/A") {
      return "It appears that your resume is missing an education section, which is an important aspect for many employers. Including your educational background can help demonstrate your qualifications and commitment to your field. I recommend adding details about your degrees, institutions attended, and any relevant certifications or coursework. If you are a recent graduate or a fresher, including your 10th and 12th-grade education can also provide a more complete picture of your academic background.";
    } else {
      return EducationData;
    }
  }

  return (
    <Stack direction="column" spacing={5} pt={5} px={5}>
      <Typography
        variant="h4"
        sx={{ fontWeight: 700 }}
        color={"#fff"}
        textAlign={"center"}
      >
        Improvements Summary
      </Typography>
      <Typography sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}>
        Revisions include content updates, design improvements, and refined
        language. These changes enhance clarity, improve readability, and align
        better with the target audience’s needs, resulting in a more effective
        and engaging document.
      </Typography>

      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Your Education info:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {EducationDatas()}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
          Required:
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
          {ImprovementsSummaryDatas()}
        </Typography>
      </Box>
    </Stack>
  );
};

// mobile view only
const MissingKeywordsMobile = ({ MissingKeywordsData }) => {
  // State to track whether the details section is open
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setData() {
    if (!MissingKeywordsData || MissingKeywordsData === "N/A") {
      return "Your resume already includes all the necessary industry-specific keywords, which ensures strong alignment with the job description. This increases the likelihood of your resume performing well in applicant tracking systems (ATS) and making a positive impression on hiring managers.";
    } else {
      return MissingKeywordsData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Missing Keywords:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            To improve content relevance and searchability, first identify
            crucial industry-specific keywords and concepts that are currently
            missing. Conduct a thorough analysis of the text to pinpoint gaps in
            terminology. Then, strategically insert these key terms to enhance
            the content's relevance and boost its search engine optimization
            (SEO). Ensure that the keywords are seamlessly integrated into the
            content without disrupting readability. This approach will help
            attract a more targeted audience and improve the overall
            effectiveness of the content in search results.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};
const MissingSectionsMobile = ({ MissingSectionsData }) => {
  // State to track whether the details section is open
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  function setData() {
    if (!MissingSectionsData || MissingSectionsData === "N/A") {
      return "If you want to achieve a perfect score, you don’t need to add any additional sections to your resume at this time. The structure and sections you’ve included are already well-organized and comprehensive. Focus on enhancing the content within the existing sections for an even stronger alignment with the job requirements.";
    } else {
      return MissingSectionsData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Missing Sections:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Review the content to confirm it covers all essential sections and
            provides a comprehensive overview of the subject. Identify any
            missing key topics, critical details, or important elements that may
            be necessary for a complete and coherent presentation. Integrate
            these components to ensure the content is thorough and addresses all
            relevant aspects. Make sure the added information blends seamlessly
            with the existing material, preserving a logical flow and enhancing
            the overall clarity and effectiveness. This approach will ensure the
            content is well-rounded and fully informative.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};
const WorkHistoryMobile = ({ WorkHistoryData }) => {
  // State to track whether the details section is open
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  function setData() {
    if (!WorkHistoryData || WorkHistoryData === "N/A") {
      return "Your resume currently lacks a work history section. Including relevant professional experience, internships, or project work will significantly strengthen your application. Even if you don't have formal work experience, consider adding volunteer work, freelance projects, or academic projects to showcase your skills and accomplishments.";
    } else {
      return WorkHistoryData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Work History:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Update or refine the work history section to accurately reflect job
            roles, responsibilities, and achievements. Clearly articulate key
            contributions, skills acquired, and the impact made in each
            position. Ensure that each entry highlights specific accomplishments
            and quantifiable results where possible, providing a detailed
            account of how you added value to the organization. Revise
            descriptions to showcase relevant expertise and effectively
            communicate your career progression. This approach will enhance the
            clarity and effectiveness of your work history, making it more
            compelling to potential employers.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const SkillsMobile = ({ SkillsData, MissingSkillsData }) => {
  // State to track whether the details section is open
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setSkillsData() {
    if (!SkillsData || SkillsData === "N/A") {
      return "It looks like your resume is missing a skills section, which is a critical component for any job application. Highlighting your technical and functional skills is essential to demonstrate your qualifications for the role. I recommend adding a list of key skills relevant to the position to improve your chances of standing out to hiring managers.";
    } else {
      return SkillsData;
    }
  }
  function setMissingSkillsData() {
    if (!MissingSkillsData || MissingSkillsData === "N/A") {
      return "Great job! Your resume showcases all the relevant skills required for this role, which is a strong indicator of your qualifications. Keep up the good work, and continue honing your expertise and learning new skills to stay ahead in your career and future opportunities.";
    } else {
      return MissingSkillsData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Skills / Achievements:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Highlight relevant skills and achievements, ensuring they align with
            the job or field you’re targeting. Emphasize how these skills
            contributed to your success and address industry-specific
            requirements and expectations. Focus on showcasing how your
            expertise directly impacted past roles, including any quantifiable
            results or notable accomplishments. Tailor your descriptions to
            demonstrate your proficiency in key areas and how you’ve effectively
            applied these skills to meet challenges and drive success. This
            targeted approach will strengthen your profile and align it with
            industry standards.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setMissingSkillsData()}
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Your Skills:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setSkillsData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const DateFormattingMobile = ({ DateFormattingData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setData() {
    if (!DateFormattingData || DateFormattingData === "N/A") {
      return "Your resume's date formatting is consistent and clear. No further adjustments are necessary in this area, as it meets the standard expectations for professional presentation.";
    } else {
      return DateFormattingData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Date Formatting:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Ensure consistency and accuracy in date formatting throughout the
            document. Choose a uniform style for all dates, such as "Month Year"
            or "MM/YYYY," and apply it consistently to maintain professionalism
            and readability. This approach helps avoid confusion or errors and
            ensures that your document appears polished and well-organized.
            Review the entire document carefully to correct any inconsistencies
            or formatting discrepancies. Adhering to a single date format will
            enhance the clarity and overall presentation of your content.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const DesignImprovementMobile = ({ DesignImprovementData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setData() {
    if (!DesignImprovementData || DesignImprovementData === "N/A") {
      return "Your resume’s design is already effective and well-organized. It presents information clearly and professionally, making it easy for hiring managers to read. Maintaining a clean and structured layout will continue to serve you well. However, always be open to revisiting your resume design periodically to ensure it remains modern and visually appealing.";
    } else {
      return DesignImprovementData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Design Improvement:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Enhance the document's design to improve readability and visual
            appeal by refining the layout and typography. Use clear, descriptive
            headings and ensure consistent font choices throughout. Apply
            appropriate spacing to separate sections and improve overall flow.
            Aim for a balanced structure that guides the reader's eye and makes
            the content engaging and easy to navigate. Adjust margins, line
            spacing, and font sizes as needed to create a polished, professional
            appearance. These design improvements will make the document more
            accessible and visually appealing.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {setData()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const LexicalAnalysisMobile = ({ LexicalAnalysisData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setData() {
    if (!LexicalAnalysisData.length || LexicalAnalysisData === "N/A") {
      return `Personal Pronouns: Overuse of personal pronouns may make your resume seem informal. Consider focusing on achievements rather than self-references.
Numericized Data: Your resume lacks sufficient numeric data to back up your achievements. Including numbers can help quantify your impact.
Vocabulary Level: The vocabulary level is too basic, which might not reflect a strong professional image. Consider using more precise and varied terms.
Reading Level: The reading level is too low, making the content seem overly simplistic. Adjust to a more professional tone.
Common Words: Overuse of common words like 'the', 'and', 'of'. Try reducing redundancy to make your resume more engaging and impactful.`;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Lexical Analysis:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Analyze and refine word choice to ensure clarity and appropriateness
            for your target audience. Use precise, accessible language that
            effectively conveys key messages. Adjust tone and terminology to
            align with the audience’s needs and expectations, making sure the
            content resonates with them. Avoid jargon or complex vocabulary
            unless necessary and appropriate. By tailoring language to the
            audience, you enhance understanding and engagement, ensuring that
            the core messages are communicated clearly and effectively. This
            approach will make the content more relevant and impactful.
          </Typography>

          <Box>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Box>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  color: "#fff",
                  fontSize: "18px",
                  mt: 2,
                }}
              >
                {LexicalAnalysisData?.length
                  ? LexicalAnalysisData?.map((item) => (
                      <ListItem sx={{ display: "list-item" }} key={item.key}>
                        {item.key}: {item?.value}
                      </ListItem>
                    ))
                  : setData()}
              </List>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const SemanticAnalysisMobile = ({ SemanticAnalysisData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  function setData() {
    if (!SemanticAnalysisData.length || SemanticAnalysisData === "N/A") {
      return `Clarity: Your resume lacks clarity in presenting your qualifications. The wording could be more concise and focused.
    Relevance: Some sections of your resume include irrelevant information that does not align with the job you're applying for. Make sure to tailor your content for each position.
    Consistency: There are inconsistencies in your language and tone, which might confuse hiring managers. Ensure that your resume is uniform and professional throughout.
    Tone: The tone of your resume is too informal or casual. A more professional tone will give a better impression to potential employers.
    Keywords: Your resume does not include enough industry-specific keywords, which could make it harder for Applicant Tracking Systems (ATS) to recognize your skills. Adding relevant keywords will improve your chances.`;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Semantic Analysis:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Review and refine the content to ensure accuracy in meaning and
            context. Verify that all information is correct and aligns with the
            intended message. Make sure the ideas are communicated clearly and
            effectively, addressing the target audience's needs and
            expectations. Adjust wording and phrasing as necessary to enhance
            clarity and coherence, ensuring that the content accurately reflects
            the intended ideas and avoids any misunderstandings. This careful
            refinement will ensure that the message is conveyed precisely and
            resonates well with the audience.
          </Typography>

          <Box>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Box>
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "inside",
                  color: "#fff",
                  fontSize: "18px",
                }}
              >
                {SemanticAnalysisData?.length
                  ? SemanticAnalysisData?.map((item) => (
                      <ListItem sx={{ display: "list-item" }} key={item.key}>
                        {item.key}: {item?.value}
                      </ListItem>
                    ))
                  : setData()}
              </List>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

const ImprovementsSummaryMobile = ({
  ImprovementsSummaryData,
  EducationData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  function ImprovementsSummaryDatas() {
    if (!ImprovementsSummaryData || ImprovementsSummaryData === "N/A") {
      return "Your resume is well-crafted and does not require an improvement summary at this time. The information you’ve provided is already strong and effectively showcases your qualifications and achievements. Keep up the good work, and continue to update your resume with any new accomplishments or skills as you progress in your career.";
    } else {
      return ImprovementsSummaryData;
    }
  }
  function EducationDatas() {
    if (!EducationData || EducationData === "N/A") {
      return "It appears that your resume is missing an education section, which is an important aspect for many employers. Including your educational background can help demonstrate your qualifications and commitment to your field. I recommend adding details about your degrees, institutions attended, and any relevant certifications or coursework. If you are a recent graduate or a fresher, including your 10th and 12th-grade education can also provide a more complete picture of your academic background.";
    } else {
      return EducationData;
    }
  }

  return (
    <Stack direction="column" pt={5}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
          background:
            "linear-gradient(0deg, rgba(20,8,49,1) 0%, rgba(41,21,89,1) 68%, rgba(61,32,126,1) 100%)",
          border: "1px solid #fff",
          color: "#fff",
          fontSize: "18px",
          fontWeight: 600,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
          color={"#fff"}
          textAlign={"left"}
        >
          Improvements Summary:
        </Typography>

        {isOpen ? (
          <ArrowDropUpIcon sx={{ color: "#fff", fontSize: "30px" }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: "#fff", fontSize: "30px" }} />
        )}
      </Box>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Box
          sx={{
            border: "1px solid #fff",
            p: 2,
            width: "90%",
            mx: "auto",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
            transition: "all 0.3s ease",
          }}
        >
          <Typography
            sx={{ color: "#fff", textAlign: "left", fontSize: "18px" }}
          >
            Revisions include content updates, design improvements, and refined
            language. These changes enhance clarity, improve readability, and
            align better with the target audience’s needs, resulting in a more
            effective and engaging document.
          </Typography>

          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Your Education info:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {EducationDatas()}
            </Typography>
          </Box>
          <Box mt={5}>
            <Typography sx={{ color: "#fff", fontWeight: 600 }} variant="h5">
              Required:
            </Typography>
            <Typography sx={{ color: "#fff", fontSize: "18px", mt: 2 }}>
              {ImprovementsSummaryDatas()}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Stack>
  );
};

export default DetailResume;
