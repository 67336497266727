import {
  DELETE_JOB_TYPE_FAIL,
  DELETE_JOB_TYPE_REQUEST,
  DELETE_JOB_TYPE_RESET,
  DELETE_JOB_TYPE_SUCCESS,
} from "../constants/jobTypeConstant";
import {
  CREATE_SKILL_FAIL,
  CREATE_SKILL_REQUEST,
  CREATE_SKILL_RESET,
  CREATE_SKILL_SUCCESS,
  SKILL_LOAD_FAIL,
  SKILL_LOAD_REQUEST,
  SKILL_LOAD_RESET,
  SKILL_LOAD_SUCCESS,
} from "../constants/skillConstant";

// load job type reducer
export const loadSkillReducer = (state = { jobType: [] }, action) => {
  switch (action.type) {
    case SKILL_LOAD_REQUEST:
      return { loading: true };
    case SKILL_LOAD_SUCCESS:
      return {
        loading: false,
        skill: action.payload.skill,
      };
    case SKILL_LOAD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SKILL_LOAD_RESET:
      return {};
    default:
      return state;
  }
};

// create job type reducer
export const createSkillReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SKILL_REQUEST:
      return { loading: true };
    case CREATE_SKILL_SUCCESS:
      return {
        loading: false,
        skill: action.payload,
      };
    case CREATE_SKILL_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_SKILL_RESET:
      return {};
    default:
      return state;
  }
};

// delete job type
export const deleteSkillReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_JOB_TYPE_REQUEST:
      return { loading: true };
    case DELETE_JOB_TYPE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case DELETE_JOB_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case DELETE_JOB_TYPE_RESET:
      return {};
    default:
      return state;
  }
};
