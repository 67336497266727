import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  allUsersPaginationAction,
  deleteSingleUserAction,
} from "../../redux/actions/userAction";

const DashUsers = () => {
  const dispatch = useDispatch();

  // Pagination state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch users and pagination data from Redux
  const { users, loading, totalPages } = useSelector(
    (state) => state.userPaginations
  );

  useEffect(() => {
    dispatch(allUsersPaginationAction(page, rowsPerPage)); // Fetch data with pagination
  }, [dispatch, page, rowsPerPage]);

  // Handle user deletion
  const deleteUserById = (e, id) => {
    if (window.confirm(`Do you really want to delete user ID: "${id}" ?`)) {
      dispatch(deleteSingleUserAction(id));
    }
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ color: "white", pb: 3 }}>
        All Users
      </Typography>

      <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<AddIcon />}
          component={Link}
          to="/admin/user/create"
          sx={{ color: "white" }}
        >
          Create User
        </Button>
      </Box>

      <Paper sx={{ bgcolor: "#fff", borderRadius: 2, p: 1, m: 1 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: 400,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S No.</TableCell>
                    <TableCell>User ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>User Status</TableCell>
                    <TableCell>Creation Date</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, i) => (
                    <TableRow key={user._id}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{user._id}</TableCell>
                      <TableCell>
                        {user?.firstName + " " + user?.lastName}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        {user.role === 1 ? "Admin" : "Regular user"}
                      </TableCell>
                      <TableCell>
                        {moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            variant="outlined"
                            size="small"
                            component={Link}
                            to={`/admin/edit/user/${user._id}`}
                            sx={{
                              bgcolor: "#1976d2",
                              color: "#fff !important",
                              "&:hover": {
                                color: "#fff",
                                bgcolor: "#115293",
                              },
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={(e) => deleteUserById(e, user._id)}
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{ color: "#fff !important" }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination */}
            <Box sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
              <Pagination
                page={page}
                count={totalPages || 1} // Total number of pages
                onChange={handleChangePage} // Handle page change
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#000",
                    borderColor: "#000",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                  },
                }}
              />
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DashUsers;
