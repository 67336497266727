import { Box, Grid, Stack, Typography } from "@mui/material";
import StatComponent from "../../component/StatComponent";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import WorkIcon from "@mui/icons-material/Work";
import CategoryIcon from "@mui/icons-material/Category";
import BoltIcon from "@mui/icons-material/Bolt";
import { Chart } from "react-google-charts";
import { data, options } from "./data/data";
import ChartComponent from "../../component/ChartComponent";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import apiClient from "../../axiosConfig";

const AdminDashboard = () => {
  // State to hold the fetched data
  const [allJobs, setAllJobs] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [jobCategory, setJobCategory] = useState([]);
  const [skills, setskills] = useState([]);
  // get users from Redux
  const { jobs, pages } = useSelector((state) => state.loadJobs);

  const getUsers = async () => {
    try {
      const { data } = await apiClient.get("/api/allusers");
      // console.log("all user", data?.count);
      setAllUsers(data?.count || []);
    } catch (err) {
      console.log(err);
    }
  };

  const getJobCategory = async () => {
    try {
      const { data } = await apiClient.get("/api/type/jobs");
      setJobCategory(data?.jobT.length || []);
    } catch (error) {
      console.log(error);
    }
  };
  const getSkills = async () => {
    try {
      const { data } = await apiClient.get("/api/skills");
      // console.log("get skills", data?.skill);
      setskills(data?.skill || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
    getJobCategory();
    getSkills();
    setAllJobs(jobs || []);
  }, []);

  const totalJobs = allJobs?.length * pages;
  const totalUsers = allUsers;
  const totalCategory = jobCategory;
  const totalSkills = skills?.length;

  // console.log("totalJobs", totalJobs);
  // console.log("totalUsers", totalUsers);
  // console.log("totalCategory", totalCategory);

  return (
    <>
      <Box>
        <Typography variant="h4" sx={{ color: "white", pb: 3 }}>
          Admin Dashboard
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "repeat(2, 1fr)",
            },
            gap: { xs: 2, sm: 3, md: 4 },
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: 2,
          }}
        >
          <Grid item>
            <StatComponent
              value={totalUsers || 10}
              icon={
                <SupervisorAccountIcon
                  sx={{ color: "#fafafa", fontSize: 30 }}
                />
              }
              description="Total Users"
              money=""
            />
          </Grid>
          <Grid item>
            <StatComponent
              value={totalJobs || 10}
              icon={<WorkIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
              description="Total Jobs"
              money=""
            />
          </Grid>
          <Grid item>
            <StatComponent
              value={totalCategory || 10}
              icon={<CategoryIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
              description="Jobs Categories"
              money=""
            />
          </Grid>
          <Grid item>
            <StatComponent
              value={totalSkills || 10}
              icon={<BoltIcon sx={{ color: "#fafafa", fontSize: 30 }} />}
              description="Total Skills"
              money=""
            />
          </Grid>
        </Grid>

        {/* <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ mt: 3 }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <ChartComponent>
            <Chart
              chartType="Bar"
              data={data}
              options={options}
              width="100%"
              height="300px"
              legendToggle
            />
          </ChartComponent>
        </Stack> */}
      </Box>
    </>
  );
};

export default AdminDashboard;
