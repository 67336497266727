import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSingleTypeAction,
  jobTypeLoadAction,
} from "../../redux/actions/jobTypeAction";

import moment from "moment";

const DashCategory = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jobTypeLoadAction());
  }, []);

  const { success: deleteSuccess } = useSelector((state) => state.deleteType);
  const { jobType, loading } = useSelector((state) => state.jobTypeAll);
  let data = [];
  data = jobType !== undefined && jobType.length > 0 ? jobType : [];

  //delete job by Id
  const deleteJobCategoryById = (e, id) => {
    if (window.confirm(`You really want to delete product ID: "${id}" ?`)) {
      dispatch(deleteSingleTypeAction(id));
    }
  };
  // Assuming you have a useEffect to handle deleteSuccess change
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(jobTypeLoadAction());
    }
  }, [deleteSuccess, dispatch]);

  const columns = [
    {
      field: "_id",
      headerName: "Category ID",
      width: 150,
      editable: true,
    },
    {
      field: "jobTypeName",
      headerName: "Category",
      width: 150,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Create At",
      width: 150,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD HH:MM:SS"),
    },

    {
      field: "Actions",
      width: 200,
      renderCell: (values) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "170px",
          }}
        >
          <Button variant="contained">
            <Link
              style={{ color: "white", textDecoration: "none" }}
              to={`/admin/edit/category/${values.row._id}`}
              sx={{
                bgcolor: "#1976d2",
                color: "#fff !important",
                "&:hover": {
                  color: "#fff",
                  bgcolor: "#115293",
                },
              }}
            >
              Edit
            </Link>
          </Button>
          <Button
            onClick={(e) => deleteJobCategoryById(e, values.row._id)}
            variant="contained"
            color="error"
            sx={{ color: "#fff !important", whiteSpace: "nowrap" }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h4" sx={{ color: "white", pb: 3 }}>
        Jobs category
      </Typography>
      <Box sx={{ pb: 2, display: "flex", justifyContent: "right" }}>
        <Button variant="contained" color="success" startIcon={<AddIcon />}>
          <Link
            style={{ color: "white", textDecoration: "none" }}
            to="/admin/category/create"
          >
            Create category
          </Link>
        </Button>
      </Box>
      <Paper
        sx={{
          bgcolor: "#fff",
          borderRadius: 2,
          p: 1,
          m: 1,
        }}
      >
        <Box
          sx={{
            height: { xs: 300, sm: 400, md: "100%" },
            width: "100%",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: 400,
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              getRowId={(row) => row._id}
              sx={{
                "& .MuiDataGrid-cell": {
                  color: "text.primary",
                },
                "& .MuiTablePagination-root": {
                  color: "text.primary",
                },
                [`& .${gridClasses.row}`]: {
                  bgcolor: (theme) =>
                    theme.palette.mode === "light"
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  "&:hover": {
                    bgcolor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                  },
                },
                "& .MuiButton-root": {
                  color: "text.primary",
                },
              }}
              rows={data}
              columns={columns}
              pageSize={3}
              rowsPerPageOptions={[3]}
              checkboxSelection
              // components={{ Toolbar: GridToolbarExport }}
            />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DashCategory;
