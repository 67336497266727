import { blue, grey, lightBlue } from "@mui/material/colors";

export const themeColors = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#13072E",
            white: "#fff",
          },
          secondary: {
            main: "#102140",
            midNightBlue: "#102140",
            white: "#fff",
          },
          textPrimary: "#516A99",
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#003366",
            white: "#003366",
          },
          secondary: {
            main: blue[500],
            midNightBlue: "#2196f3",
          },
          background: {
            default: "#1e1e1e",
          },
          text: {
            primary: "#fff",
            secondary: grey[500],
          },
        }),
  },
});
