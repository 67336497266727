// apiClient.js
import axios from "axios";

const config = {
  development: {
    apiBaseUrl: "http://localhost:5001",
  },
  production: {
    apiBaseUrl: "https://jobs-mern-backend.unifiedmentor.com",
  },
};

const env = "production";

// console.log(config[env].apiBaseUrl);

const apiClient = axios.create({
  baseURL: config[env].apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// export const AtsClient = axios.create({
//   baseURL: "https://mern-ats-resume-backend.vercel.app",
//   withCredentials: true,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

export default apiClient;

// apiClient.js
