import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import styled from "styled-components";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { toast } from "react-toastify";

import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import ResumeCheckerImg from "../images/ResumeChecker.png";
import BluredCircle from "../images/BluredCircle.png";
import LeftImage from "../images/Left.png";
import RightImage from "../images/Right.png";
import { JobDetailsData } from "../Utils/JobDetailData";
import LoadingAnimation from "../images/loadingAnimation.gif";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import apiClient from "../axiosConfig";
import { Link } from "react-router-dom";
import Unfortunately from "../images/Unfortunately.gif";
import LimitGif from "../images/limit.gif";
const ResumeCheckerMainImage = styled("img")({
  width: "50%",
  height: "auto",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const LeftImageBox = styled("img")({
  width: "200px",
  height: "200px",
  "@media (max-width: 900px)": {
    display: "none",
  },
});

const RightImageBox = styled("img")({
  width: "200px",
  height: "200px",
  "@media (max-width: 900px)": {
    display: "none",
  },
});

const Textarea = styled("textarea")({
  boxSizing: "border-box",
  width: "100%",
  fontSize: "18px",
  fontWeight: 400,
  height: "auto",
  color: "whitesmoke",
  padding: "12px",
  borderRadius: "12px 12px 0 12px",
  border: "1px solid #c2c2c2",
  outline: "none",
  background: "#13072E",
  "&::placeholder": {
    color: "#c2c2c2",
    opacity: 1,
  },
  "&:disabled": {
    cursor: "not-allowed",
  },
});

const CircleImage1 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(10px)",
  top: "0",
  left: "-15%",

  "@media (max-width: 600px)": {
    display: "none",
  },
});
const CircleImage2 = styled("img")({
  width: "450px",
  height: "auto",
  position: "absolute",
  filter: "blur(10px)",
  bottom: "0",
  right: "0%",

  "@media (max-width: 600px)": {
    display: "none",
  },
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ResumeChecker = ({
  setFinalAtsResult,
  finalAtsResult,
  setCurrentJobDesc,
}) => {
  const { palette } = useTheme();
  const [jobDescription, setJobDescription] = useState("");
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const [selectedJobDescription, setSelectedJobDescription] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [accessLimit, setAccessLimit] = useState(false);
  const [accessLimitCount, setAccessLimitCount] = useState(0);

  // Styled components
  useEffect(() => {
    if (selectedJobTitle) {
      const selectedJob = JobDetailsData?.find(
        (item) => item.title === selectedJobTitle
      );

      setSelectedJobDescription(selectedJob?.description);
      // console.log(selectedJob?.description);
    }
  }, [selectedJobTitle]);

  const getAccsesLimitScore = async () => {
    try {
      const response = await apiClient.get("/api/ats/reset-access-limit");
      if (response) {
        if (response?.data) {
          setAccessLimitCount(3 - Number(response?.data?.accessCount));
        }
      }
    } catch (error) {
      console.error("Error fetching access limit", error);
    }
  };

  useEffect(() => {
    getAccsesLimitScore();
  }, []);

  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          minHeight: "calc(100vh - 140px)",
          bgcolor: palette.primary.main,
        }}
      >
        {loading && <LoadingComponent palette={palette} />}

        <Container
          maxWidth="xl"
          sx={{
            padding: { xs: 2, md: 5 },
            position: "relative",
          }}
        >
          <CircleImage1 src={BluredCircle} alt="circle" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography
              variant="h1"
              sx={{ color: "#fff", fontSize: "36px", fontWeight: 700, pb: 5 }}
            >
              Track Resume
            </Typography>
            <ResumeCheckerMainImage
              src={ResumeCheckerImg}
              alt="Resume Checker"
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 10,
            }}
          >
            <Stack
              direction={"row"}
              width={"100%"}
              mt={2}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={5}
            >
              <LeftImageBox src={LeftImage} alt="left-image" />
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: { xs: "flex-start", md: "center" },
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "24px",
                      fontWeight: 700,
                      textAlign: "left",
                      mb: 2,
                    }}
                  >
                    Job Description /{" "}
                    <span
                      style={{
                        color: "#c2c2c2",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      Optional
                    </span>
                  </Typography>

                  <Box
                    sx={{
                      background:
                        "linear-gradient(90deg,#10062a,#291559 68.1%,#3d207e)",
                      borderRadius: "30px",
                      padding: "5px 10px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #fff",

                      mb: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: 700,
                        textAlign: "left",
                      }}
                    >
                      Today’s Credits : {accessLimitCount}
                    </Typography>
                  </Box>
                </Box>

                <Textarea
                  aria-label="job description textarea"
                  placeholder="Paste the job description here ... (Optional) or Select from the dropdown"
                  rows={20}
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  disabled={selectedJobTitle.length ? true : false}
                />
                {/* dropdown */}
                <StyledSelect
                  JobDetailsData={JobDetailsData}
                  jobDescription={jobDescription}
                  palette={palette}
                  selectedJobTitle={selectedJobTitle}
                  setSelectedJobTitle={setSelectedJobTitle}
                />
                {/* file upload */}
                <StyledFileUpload
                  palette={palette}
                  jobDescription={jobDescription}
                  selectedJobDescription={selectedJobDescription}
                  // this is for matching purposes only
                  setCurrentJobDesc={setCurrentJobDesc}
                  setLoading={setLoading}
                  loading={loading}
                  setSelectedFile={setSelectedFile}
                  selectedFile={selectedFile}
                  setFinalResult={setFinalAtsResult}
                  setShowResult={setShowResult}
                  setAccessLimit={setAccessLimit}
                  setAccessLimitCount={setAccessLimitCount}
                />
              </Box>
              <RightImageBox src={RightImage} alt="right-image" />
            </Stack>
          </Box>
          <CircleImage2 src={BluredCircle} alt="circle" />
        </Container>
        {showResult && (
          <ResponsePopup
            setShowResult={setShowResult}
            finalResult={finalAtsResult}
          />
        )}

        {accessLimit && <AccessLimit setAccessLimit={setAccessLimit} />}
      </Box>
      <Footer />
    </Box>
  );
};

const StyledSelect = ({
  JobDetailsData,
  palette,
  selectedJobTitle,
  setSelectedJobTitle,
  jobDescription,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setSelectedJobTitle(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <FormControl
        variant="outlined"
        sx={{
          width: "100%",
          maxWidth: "600px",
          mt: 3,
        }}
      >
        <InputLabel
          id="job-title-select-label"
          sx={{
            color: "#fff",
            "&.Mui-focused": {
              color: "#fff",
            },
            "&:disabled": {
              cursor: "not-allowed",
              color: "#fff",
            },
          }}
        >
          Select Job Title
        </InputLabel>
        <Select
          labelId="job-title-select-label"
          id="job-title-select"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={selectedJobTitle}
          disabled={jobDescription?.length ? true : false}
          onChange={handleChange}
          label="Select Job Title"
          sx={{
            color: "#fff",
            backgroundColor: palette.primary.main,

            "&:disabled": {
              cursor: "not-allowed",
              color: "#fff",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
              borderWidth: "2px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
            },
            "& .MuiSvgIcon-root": {
              color: "#fff",
            },
          }}
        >
          <MenuItem value="">
            <em>Select Job Title</em>
          </MenuItem>
          {JobDetailsData?.map((item, index) => (
            <MenuItem key={index} value={item.title}>
              {item.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const StyledFileUpload = ({
  palette,
  jobDescription,
  selectedJobDescription,
  setLoading,
  loading,
  setSelectedFile,
  selectedFile,
  setFinalResult,
  setShowResult,
  setCurrentJobDesc,
  setAccessLimit,
  setAccessLimitCount,
}) => {
  const [takeOneDescription, setTakeOneDescription] = useState("");

  useEffect(() => {
    if (jobDescription.length > 0) {
      setTakeOneDescription(jobDescription);
    } else {
      setTakeOneDescription(selectedJobDescription);
    }
  }, [jobDescription, selectedJobDescription]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    // console.log(file);
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.error("Please select a file");
      return;
    }

    if (!jobDescription && !selectedJobDescription) {
      toast.error(
        "Please select a job description or paste the job description"
      );
      return;
    }

    if (takeOneDescription.length < 600) {
      toast.error("Job description should be at least 600 characters long");
      return;
    }

    // console.log("selectedFile", selectedFile);

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("resume", selectedFile);
      formData.append("jobDescription", takeOneDescription);

      const response = await apiClient.post("/api/ats/analyze", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        // console.log("Response data:", response.data);
        setFinalResult(response?.data);
        if (typeof response?.data?.accessCount === "string") {
          setAccessLimitCount("Infinite");
        } else {
          setAccessLimitCount(3 - Number(response?.data?.accessCount));
        }
        setShowResult(true);

        const storedJobDesc = localStorage?.getItem("jobDescription");

        if (storedJobDesc !== takeOneDescription) {
          // If different, update localStorage and state
          localStorage.setItem("jobDescription", takeOneDescription);
          setCurrentJobDesc(takeOneDescription);
          localStorage.setItem("jobDescriptionMatchIs", false);
          // console.log("New job description saved");
        } else {
          // console.log("Job description matches existing one");
          localStorage.setItem("jobDescriptionMatchIs", true);
        }
        // toast.success("File submitted successfully");
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting file:", error);

      if (
        error.response?.status === 403 ||
        error.response?.data?.message === "Daily access limit reached."
      ) {
        toast.error(error.response?.data?.message || "Something went wrong");
        setAccessLimit(true);
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "start",
          gap: 2,
          mt: 2,
          width: "100%",
          // Custom media query for 480px
          "@media (max-width: 480px)": {
            flexDirection: "column",
          },
        }}
      >
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          sx={{
            bgcolor: "#fff",
            color: palette.primary.main,
            borderRadius: "25px",
            "&:hover": { bgcolor: "#f0f0f0" },
            minWidth: "200px",
            // Custom media query for 480px
            "@media (max-width: 450px)": {
              width: "100%",
              minWidth: "100%",
            },
          }}
        >
          Upload Your Resume
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            accept="application/pdf"
          />
        </Button>
        <Button
          onClick={handleSubmit}
          variant="outlined"
          disabled={loading || !selectedFile}
          sx={{
            minWidth: "200px",
            color: "#fff",
            borderRadius: "25px",
            border: "1px solid #fff",
            "@media (max-width: 450px)": {
              minWidth: "100%",
              width: "100%",
            },
            "&:disabled": {
              cursor: "not-allowed",
              border: "1px solid #f0f0f0",
              bgcolor: "#f0f0f0",
              color: "#000",
            },
            "&:hover": {
              border: "1px solid #f0f0f0",
              bgcolor: "#f0f0f0",
              color: "#000",
            },
          }}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Stack>

      {selectedFile && (
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            color: "#fff",
            bgcolor: "rgba(0, 0, 0, 0.3)",
            padding: "4px 8px",
            borderRadius: "4px",
            maxWidth: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Selected file: {selectedFile.name}
        </Typography>
      )}
    </Box>
  );
};

const LoadingComponent = ({ palette }) => {
  // const tabletDevice = useMediaQuery("(max-width: 650px)"); //80%
  const bigSmartPhone = useMediaQuery("(max-width: 520px)"); //60%
  // const smallSmartPhone = useMediaQuery("(max-width: 420px)"); //40%
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 50,
        backgroundColor: palette.primary.main,
        backdropFilter: "blur(8px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          color: "#fff",
          width: "100%",
          height: "100%",
          scale: bigSmartPhone ? "1.5" : 1,
          "@media (max-width: 650px)": {
            height: "80%",
          },
          "@media (max-width: 520px)": {
            height: "60%",
          },
          "@media (max-width: 420px)": {
            height: "40%",
          },
        }}
      >
        <img
          src={LoadingAnimation}
          alt="loading"
          width={"100%"}
          height={"100%"}
        />
      </Box>
    </Box>
  );
};

function CircularProgressWithLabel(props) {
  const isLessThan50 = Number(props.value) < 50;
  const color = isLessThan50 ? "red" : "green";
  const textValue = () => {
    if (Number(props.value) < 50) {
      return `Weak`;
    } else if (Number(props.value) >= 50 && Number(props.value) < 60) {
      return `Average`;
    } else if (Number(props.value) >= 60 && Number(props.value) < 80) {
      return `Good`;
    } else {
      return `Strong`;
    }
  };
  const size = 250; // Size in pixels
  const responsiveSize = 200; // Size in pixels

  const mediaQueryforsmallScreen = useMediaQuery("(max-width: 450px)");
  // console.log(mediaQueryforsmallScreen);

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        width: size,
        height: size,

        "@media (max-width: 450px)": {
          height: responsiveSize,
          width: responsiveSize,
        },
      }}
    >
      {/* White background circle */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={mediaQueryforsmallScreen ? responsiveSize : size}
        thickness={4}
        sx={{
          color: "white",
          position: "absolute",
        }}
      />
      {/* Colored progress circle */}
      <CircularProgress
        variant="determinate"
        {...props}
        size={mediaQueryforsmallScreen ? responsiveSize : size}
        thickness={4}
        sx={{
          color: color,
          position: "absolute",
          "& .MuiCircularProgress-circle": {
            strokeLinecap: "round",
          },
        }}
      />
      {/* Percentage text */}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{ color: color, fontWeight: "bold" }}
          textAlign={"center"}
          fontSize={mediaQueryforsmallScreen ? 32 : 42}
        >
          {`${Math.round(props.value)}%`}
          <br />
          {textValue()}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const ResponsePopup = ({ setShowResult, finalResult }) => {
  // console.log("finalResult", finalResult);

  const [newValue, setNewValue] = useState(0);

  class JobMatchResult {
    constructor(data) {
      this.resumeText = data?.resumeText;
      this.analysis = data?.analysis;
    }

    getResumeText() {
      return this.resumeText;
    }

    getAnalysis() {
      return this.analysis;
    }

    getJobMatchPercentage() {
      return this.analysis["Job Description Match"];
    }

    getMissingKeywords() {
      return this.analysis["Missing Keywords"]
        .split(",")
        .map((keyword) => keyword.trim());
    }

    getMissingSkills() {
      return this.analysis["Missing Skills"]
        ? this.analysis["Missing Skills"]
            .split(",")
            .map((skill) => skill.trim())
        : [];
    }

    getMissingSections() {
      return this.analysis["Missing Sections"]
        ? this.analysis["Missing Sections"]
            .split(",")
            .map((section) => section.trim())
        : [];
    }

    getImprovementsSummary() {
      return this.analysis["Improvements Summary"];
    }

    getDesignImprovement() {
      return this.analysis["Design Improvement"];
    }

    getContactInfo() {
      return {
        phone: this.analysis["Phone Number"],
        email: this.analysis["Email Address"],
        linkedin: this.analysis["LinkedIn URL"],
      };
    }

    getEducation() {
      return this.analysis["Education"];
    }

    getWorkHistory() {
      return this.analysis["Work History"];
    }

    getSkillsAndAchievements() {
      return this.analysis["Skills / Achievements"]
        .split("\n")
        .map((item) => item.trim());
    }

    // Generic method to get any field from the analysis
    getAnalysisField(fieldName) {
      return this.analysis[fieldName];
    }
  }

  const result = new JobMatchResult(finalResult);

  const getJobMatchPercentage = result?.getJobMatchPercentage();

  const value = getJobMatchPercentage.split("%")[0];

  const getLocalDesc = () => {
    const desc = localStorage.getItem("jobDescriptionMatchIs");
    if (desc) {
      if (desc === "true") {
        return true;
      } else {
        return false;
      }
    }
  };

  const matchResumeText = (data) => {
    const dbText = data?.resumeText?.length > 0 ? data?.resumeText : null;
    const LsText = localStorage.getItem("response");
    const LsResumeText = LsText ? JSON.parse(LsText)?.resumeText : null;

    if (dbText && LsResumeText) {
      if (dbText === LsResumeText) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    const getJobValue = () => {
      const LsText = localStorage.getItem("response");
      const response = LsText ? JSON.parse(LsText) : null;
      if (response) {
        // console.log("response from ResumeChecker by local storage", response);
        const analysis = response?.analysis;
        if (analysis) {
          const value = analysis["Job Description Match"].split("%")[0];
          setNewValue(value);
          // console.log("Job Description Match", value);
        }
      } else {
        // console.log("No data found in local storage");
        setNewValue(value);
      }
    };
    getLocalDesc();
    matchResumeText(finalResult);
    getJobValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalResult]);

  // console.log(newValue, "newValue");

  // console.log("value", value);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 50,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(8px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, sm: 4 },
      }}
    >
      {value ? (
        value > 5 ? (
          <Stack
            sx={{
              color: "#fff",
              width: "100%",
              maxWidth: { xs: "100%", sm: "800px" },
              background:
                "linear-gradient(94.89deg, #13072ed9 1.2%, #3f2182d9 97.79%)",
              bgcolor: "#000",
              borderRadius: 8,
              border: "1px solid #c2c2c2",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <CancelIcon
              sx={{
                position: "absolute",
                top: 20,
                right: 25,
                cursor: "pointer",
                fontSize: "2rem",
                "&:hover": { color: "#EFC778" },
              }}
              onClick={() => setShowResult(false)}
            />

            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent={{ xs: "center", sm: "space-between" }}
              alignItems="center"
              spacing={{ xs: 3, sm: 4 }}
              p={{ xs: 3, sm: 4 }}
            >
              <Box
                sx={{
                  width: "100%",
                  "@media (max-width: 1600px)": {
                    width: "40%",
                  },
                  "@media (max-width: 720px)": {
                    width: "60%",
                  },
                }}
              >
                <CircularProgressWithLabel value={newValue} />
              </Box>
              <Stack
                spacing={3}
                sx={{ width: { xs: "100%", sm: "60%" } }}
                alignItems={{ xs: "center", sm: "flex-start" }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}
                >
                  Job Description Match: {newValue}%
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "0.9rem", sm: "1rem" },
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  Your resume aligns with {newValue}%. It highlights relevant
                  skills, showing potential to contribute effectively.
                </Typography>
                <Link to="/ats/resume-checker/analyze">
                  <Button
                    sx={{
                      color: "#000",
                      bgcolor: "#fff",
                      border: "1px solid #c2c2c2",
                      borderRadius: "25px",
                      padding: "8px 16px",
                      width: "fit-content",
                      minWidth: "150px",
                      alignSelf: { xs: "center", sm: "flex-start" },
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.8)",
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                      Resolve now
                    </Typography>
                    <ArrowForwardIcon
                      sx={{
                        color: "#fff",
                        bgcolor: "#000",
                        borderRadius: "50%",
                        fontSize: "1.2rem",
                        padding: "2px",
                      }}
                    />
                  </Button>
                </Link>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack
            sx={{
              bgcolor: "#13072E",
              borderRadius: 8,
              width: { xs: "100%", sm: "95%" }, // Full width on small screens
              height: "95%",
              overflow: "auto",
              p: { xs: 2, sm: 3 }, // Padding adjustments for mobile
            }}
            justifyContent={"center"}
            alignItems="center"
            spacing={3}
            flexDirection={"column"}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                  textAlign: "center",
                  mb: 3,
                  fontWeight: "bold",
                  color: "#fff",
                }}
              >
                Unfortunately, we couldn’t process your resume.
              </Typography>
              <Box
                sx={{
                  width: { xs: "80%", sm: "45%", md: "35%" },
                  height: "auto",
                }}
              >
                {" "}
                <img
                  src={Unfortunately}
                  alt="unfortunately"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>

              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1.1rem" },
                  textAlign: "center",
                  mb: 3,
                  fontWeight: "bold",
                  color: "#fff",
                  width: { xs: "100%", sm: "80%" },
                }}
              >
                It seems like you've uploaded something other than a resume.
                Please ensure that you are submitting a valid resume file so we
                can proceed with the analysis. If you need help, feel free to
                reach out!
              </Typography>

              {/* <Box>
                <Stack
                  sx={{
                    background:
                      "linear-gradient(94.89deg, #13072ed9 1.2%, #3f2182d9 97.79%)",
                    borderRadius: "20px",
                    padding: { xs: "0.5rem 1rem", sm: "1rem 2rem" }, // Responsive padding
                    color: "#fff",
                    textAlign: "left",
                    width: "100%",
                    margin: "auto",
                  }}
                >
                  <Typography>
                    1. It looks like you uploaded the wrong file
                    ("Recipt29946419 1.pdf"). Please upload your resume in PDF
                    format.
                  </Typography>
                  <Typography>
                    2. Clearly label your work experience section as
                    "Experience" or "Professional Experience" to ensure it's
                    easily recognized.
                  </Typography>
                  <Typography>
                    3. Avoid using creative or unconventional section titles, as
                    it may cause issues with ATS software scanning your resume.
                  </Typography>
                </Stack>
              </Box> */}

              <Button
                variant="contained"
                onClick={() => setShowResult(false)}
                sx={{
                  color: "#000",
                  bgcolor: "#fff",
                  border: "1px solid #c2c2c2",
                  borderRadius: "25px",
                  padding: "8px 16px",
                  width: "fit-content",
                  minWidth: "150px",
                  alignSelf: "center",
                  "&:hover": {
                    bgcolor: "rgba(255, 255, 255, 0.8)",
                  },
                }}
              >
                Try Again
              </Button>
            </Box>
          </Stack>
        )
      ) : null}
    </Box>
  );
};

const AccessLimit = ({ setAccessLimit }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 50,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(8px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, sm: 4 },
      }}
    >
      <Stack
        sx={{
          bgcolor: "#13072E",
          borderRadius: 8,
          width: { xs: "100%", sm: "95%" }, // Full width on small screens
          height: "95%",
          overflow: "auto",
          p: { xs: 2, sm: 3 }, // Padding adjustments for mobile
        }}
        justifyContent={"center"}
        alignItems="center"
        spacing={3}
        flexDirection={"column"}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.2rem", sm: "1.5rem" },
              textAlign: "center",
              mb: 3,
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            Unfortunately, we couldn’t process your resume.
          </Typography>

          <Box
            sx={{
              width: { xs: "80%", sm: "45%", md: "20%" },
              height: "auto",
            }}
          >
            {" "}
            <img
              src={LimitGif}
              alt="unfortunately"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.1rem" },
              textAlign: "center",
              mb: 3,
              fontWeight: "bold",
              color: "#fff",
              width: { xs: "100%", sm: "80%" },
            }}
          >
            You have reached the free upload limit for today.
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.1rem" },
              textAlign: "center",
              mb: 3,
              fontWeight: "bold",
              color: "#fff",
              width: { xs: "100%", sm: "80%" },
            }}
          >
            Please wait 24 hours before attempting to upload again. We
            appreciate your patience and understanding.
          </Typography>
          <Button
            variant="contained"
            onClick={() => setAccessLimit(false)}
            sx={{
              color: "#000",
              bgcolor: "#fff",
              border: "1px solid #c2c2c2",
              borderRadius: "25px",
              padding: "8px 16px",
              width: "fit-content",
              minWidth: "150px",
              alignSelf: "center",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.8)",
              },
            }}
          >
            Try Again
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default ResumeChecker;
