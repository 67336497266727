import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Button, InputBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

const validationSchema = yup.object({
  search: yup
    .string("Enter your search query")
    .required("This field cannot be empty"),
});

const SearchInputEl = () => {
  const navigate = useNavigate();

  const onSubmit = (values, actions) => {
    const { search } = values;
    if (search.trim()) {
      navigate(`/search/${search}`);
    } else {
      navigate("/");
    }
    actions.resetForm();
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      search: "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "50%" },
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <InputBase
          startAdornment={
            <SearchIcon
              sx={{
                color: "#a9a9a9",
                fontSize: "30px",
                marginRight: "10px",
              }}
            />
          }
          sx={{
            bgcolor: "white",
            padding: { xs: "10px", sm: "10px" },
            color: "rgba(0, 0, 0, 0.9)",
            borderTopLeftRadius: { xs: "15px", sm: "15px" },
            borderBottomLeftRadius: { xs: "15px", sm: "15px" },
            borderBottomRightRadius: { xs: "15px", sm: "0px" },
            borderTopRightRadius: { xs: "15px", sm: "0px" },
            height: { xs: "50px", sm: "50px" },
            width: { xs: "100%", sm: "100%", md: "50%" },
            fontSize: { xs: "14px", sm: "16px" },
            border: "1px solid #a9a9a9",
            flex: 1,
            "&:hover": {
              border: "1px solid #a9a9a9",
            },
            "&:focus": {
              border: "1px solid #a9a9a9",
            },
          }}
          id="search"
          name="search"
          label="search"
          placeholder="ex: developer, front end"
          value={values.search}
          onChange={handleChange}
          error={touched.search && Boolean(errors.search)}
        />

        <Button
          color="primary"
          variant="contained"
          type="submit"
          sx={{
            borderTopRightRadius: { xs: "15px", sm: "15px" },
            borderBottomRightRadius: { xs: "15px", sm: "15px" },
            height: { xs: "50px", sm: "50px" },
            marginTop: { xs: "10px", sm: "0px" },
            flex: { xs: "none", sm: "0 0 auto" },
          }}
          disabled={isSubmitting}
        >
          Search
        </Button>
      </Box>
      <Box
        component="span"
        sx={{
          color: "red",
          padding: "5px",
          borderRadius: "5px",
          fontSize: "16px",
          fontWeight: "bold",
          display: "block",
          textAlign: "center",
          width: "fit-content",
          margin: "auto",
        }}
      >
        {touched.search && errors.search}
      </Box>
    </form>
  );
};

export default SearchInputEl;
