import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProSidebarProvider } from "react-pro-sidebar";
import LogIn from "./pages/LogIn";
import UserDashboard from "./pages/user/UserDashboard";
import UserRoute from "./component/UserRoute";
import AdminRoute from "./component/AdminRoute";
import Layout from "./pages/global/Layout";
import UserJobsHistory from "./pages/user/UserJobsHistory";
import UserInfoDashboard from "./pages/user/UserInfoDashboard";
import AdminDashboard from "./pages/admin/AdminDashboard";
// import SingleJob from "./pages/SingleJob";
import DashUsers from "./pages/admin/DashUsers";
import DashJobs from "./pages/admin/DashJobs";
import Register from "./pages/Register";
import DashCategory from "./pages/admin/DashCategory";
import DashCreateJob from "./pages/admin/DashCreateJob";
import DashCreateCategory from "./pages/admin/DashCreateCategory";
import DashCreateSkill from "./pages/admin/DashCreateSkill";
import DashEditSkill from "./pages/admin/DashEditSkill";
import DashEditJob from "./pages/admin/DashEditJob";
import DashEditUser from "./pages/admin/DashEditUser";
import { createTheme } from "@mui/material/styles";
import { themeColors } from "./theme";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import DashCreateUser from "./pages/admin/DashCreateUser";
import DashSkill from "./pages/admin/DashSkill";
import DashEditCategory from "./pages/admin/DashEditCategory";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SingleJobNewPage from "./pages/SingleJobNewPage";
import Ats from "./pages/Ats";
import ResumeChecker from "./pages/ResumeChecker";
import DetailResume from "./pages/DetailResume";

// HOC Design Pattern
const UserDashboardHOC = Layout(UserDashboard);
const UserJobsHistoryHOC = Layout(UserJobsHistory);
const UserInfoDashboardHOC = Layout(UserInfoDashboard);
const AdminDashboardHOC = Layout(AdminDashboard);
const DashCreateUserHOC = Layout(DashCreateUser);
const DashUsersHOC = Layout(DashUsers);
const DashJobsHOC = Layout(DashJobs);
const DashCategoryHOC = Layout(DashCategory);
const DashAdminEditCategoryHOC = Layout(DashEditCategory);
const DashSkillHOC = Layout(DashSkill);
const DashCreateJobHOC = Layout(DashCreateJob);
const DashCreateCategoryHOC = Layout(DashCreateCategory);
const DashCreateSkillHOC = Layout(DashCreateSkill);
const DashEditSkillHOC = Layout(DashEditSkill);
const DashAdminEditJobHOC = Layout(DashEditJob);
const DashAdminEditUserHOC = Layout(DashEditUser);

const App = () => {
  const { mode } = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeColors(mode)), [mode]);
  const [finalAtsResult, setFinalAtsResult] = useState({});
  const [dataToSend, setDataToSend] = useState({});
  const [currentJobDesc, setCurrentJobDesc] = useState({});

  // console.log(
  //   "resumeTextFromLocalStorage -----------------------------> : ",
  //   typeof resumeTextFromLocalStorage || "No data found in localStorage",
  //   "resumeTextFromDatabase ----------------------------------> : ",
  //   typeof resumeTextFromDatabase || "No data found in database"
  // );

  // Function to get resume text from localStorage
  const getResumeTextFromLocalStorage = () => {
    const dataFromLocalStorage = localStorage.getItem("response");
    // console.log("Data from Local Storage:", dataFromLocalStorage);
    if (dataFromLocalStorage) {
      const parsedData = JSON.parse(dataFromLocalStorage);

      // console.log(
      //   "Parsed Resume Text from Local Storage:",
      //   parsedData?.resumeText
      // );
      return parsedData;
    } else {
      // console.log("No data found in localStorage");

      return null;
    }
  };

  const getDataFromDatabase = () => {
    if (
      finalAtsResult &&
      typeof finalAtsResult === "object" &&
      Object.keys(finalAtsResult).length > 0
    ) {
      return finalAtsResult;
    } else {
      // console.log("No valid data found in database");

      return null;
    }
  };
  const getJobMatchState = () => {
    const jobDescriptionMatch = localStorage.getItem("jobDescriptionMatchIs");
    if (jobDescriptionMatch) {
      if (jobDescriptionMatch === "true") {
        // console.log("Job description matches existing one");
        return true;
      } else {
        // console.log("Job description does not match existing one");
        return false;
      }
    }
  };

  // Function to compare strings
  function matchStrings(text1, text2) {
    if (!text1 || !text2) {
      // console.log("Both text1 and text2 are required!");
      return false;
    }
    return text1 === text2;
  }

  // Function to compare resume texts and set the data
  const compareAndSetData = () => {
    // console.log("compareAndSetData called"); // Add this log
    const newDbData = getDataFromDatabase();
    const newLSData = getResumeTextFromLocalStorage();
    const dbResumeText = newDbData?.resumeText || "";
    const LSResumeText = newLSData?.resumeText || "";

    // console.log(
    //   "compareAndSetData -> db:",
    //   dbResumeText?.length,
    //   "LS:",
    //   LSResumeText?.length
    // );

    // console.log("LSResumeText", newLSData);

    // Proceed only if both resume texts are available
    if (LSResumeText?.length > 0 && dbResumeText?.length > 0) {
      const match = matchStrings(LSResumeText, dbResumeText);
      // console.log("compareAndSetData -> string match:", match);

      const currentJobDescMatch = getJobMatchState();

      // console.log(
      //   match && currentJobDescMatch === true
      //     ? "match && currentJobDescMatch matched 0.1 : true"
      //     : " match && currentJobDescMatch does not match 0.1 : false"
      // );

      if (match && currentJobDescMatch) {
        // console.log(
        //   "Resume texts and job descriptions match. Using localStorage data. 1"
        // );
        if (
          newLSData &&
          typeof newLSData === "object" &&
          Object.keys(newLSData).length > 0
        ) {
          setDataToSend(newLSData);
        }
      } else {
        // console.log(
        //   "Resume texts or job descriptions don't match. Using database data. 2"
        // );
        const dbData = getDataFromDatabase();
        setDataToSend(dbData);
        // console.log("seting dbData when both texts do not match: 3:", dbData);
        localStorage.setItem("response", JSON.stringify(dbData));
      }
    } else {
      // console.log(
      //   "One resume text are different or empty. Using database data. 4"
      // );
      const dbData = getDataFromDatabase();

      if (
        dbData &&
        typeof dbData === "object" &&
        Object.keys(dbData).length > 0
      ) {
        setDataToSend(dbData);
        localStorage.setItem("response", JSON.stringify(dbData));
        // console.log("Setting dbData when both texts are empty: 5", dbData);
      } else {
        // console.log("No valid data received from database 6");
        setDataToSend(newLSData);
      }
    }
  };

  useEffect(() => {
    const fetchAndCompareData = () => {
      getResumeTextFromLocalStorage();
      getDataFromDatabase();
      compareAndSetData();
    };

    fetchAndCompareData();
    // eslint-disable-next-line
  }, [finalAtsResult]);

  // console.log("App.js - dataToSend:", dataToSend);

  return (
    <>
      <ToastContainer />

      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <ProSidebarProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/search/location/:location" element={<Home />} />
                <Route path="/search/:keyword" element={<Home />} />
                <Route path="/login" element={<LogIn />} />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/ats"
                  element={
                    <UserRoute>
                      <Ats />
                    </UserRoute>
                  }
                />
                <Route
                  path="/ats/resume-checker"
                  element={
                    <UserRoute>
                      <ResumeChecker
                        setFinalAtsResult={setFinalAtsResult}
                        finalAtsResult={finalAtsResult}
                        setCurrentJobDesc={setCurrentJobDesc}
                      />
                    </UserRoute>
                  }
                />
                <Route
                  path="/ats/resume-checker/analyze"
                  element={
                    <UserRoute>
                      <DetailResume
                        finalAtsResult={dataToSend}
                        setFinalAtsResult={setFinalAtsResult}
                      />
                    </UserRoute>
                  }
                />
                {/* <Route path="/job/:id" element={<SingleJob />} /> */}
                <Route path="/job/:id" element={<SingleJobNewPage />} />
                <Route
                  path="/admin/dashboard"
                  element={
                    <AdminRoute>
                      <AdminDashboardHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/users"
                  element={
                    <AdminRoute>
                      <DashUsersHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/user/create"
                  element={
                    <AdminRoute>
                      <DashCreateUserHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/edit/user/:id"
                  element={
                    <AdminRoute>
                      <DashAdminEditUserHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/jobs"
                  element={
                    <AdminRoute>
                      <DashJobsHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/category"
                  element={
                    <AdminRoute>
                      <DashCategoryHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/edit/category/:id"
                  element={
                    <AdminRoute>
                      <DashAdminEditCategoryHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/skill"
                  element={
                    <AdminRoute>
                      <DashSkillHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/job/create"
                  element={
                    <AdminRoute>
                      <DashCreateJobHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/edit/job/:id"
                  element={
                    <AdminRoute>
                      <DashAdminEditJobHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/category/create"
                  element={
                    <AdminRoute>
                      <DashCreateCategoryHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/skill/create"
                  element={
                    <AdminRoute>
                      <DashCreateSkillHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/admin/skill/:id"
                  element={
                    <AdminRoute>
                      <DashEditSkillHOC />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/user/dashboard"
                  element={
                    <UserRoute>
                      <UserDashboardHOC />
                    </UserRoute>
                  }
                />
                <Route
                  path="/user/jobs"
                  element={
                    <UserRoute>
                      <UserJobsHistoryHOC />
                    </UserRoute>
                  }
                />
                <Route
                  path="/user/info"
                  element={
                    <UserRoute>
                      <UserInfoDashboardHOC />
                    </UserRoute>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </ProSidebarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
