import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { createSkillAction } from "../../redux/actions/skillAction";

const validationSchema = yup.object({
  skillName: yup.string("Enter a Skill").required("Skill is required"),
});

const DashCreateCategory = () => {
  const { user } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      user: user && user._id,
      skillName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      // console.log(values);
      dispatch(createSkillAction(values));
      //alert(JSON.stringify(values, null, 2));
      actions.resetForm();
    },
  });

  return (
    <>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 4,
        }}
      >
        <Box
          onSubmit={formik.handleSubmit}
          component="form"
          className="form_style border-style"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="h5" component="h2" sx={{ pb: 3 }}>
              Update a Skill
            </Typography>
            <TextField
              sx={{ mb: 3 }}
              fullWidth
              id="skillName"
              label="Skill"
              name="skillName"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Skill name"
              value={formik.values.skillName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.skillName && Boolean(formik.errors.skillName)
              }
              helperText={formik.touched.skillName && formik.errors.skillName}
            />

            <Button fullWidth variant="contained" type="submit">
              Update Skill
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashCreateCategory;
