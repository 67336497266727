import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import WorkIcon from "@mui/icons-material/Work";
import { Link, useNavigate } from "react-router-dom";
import { use, useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAction } from "../redux/actions/userAction";
import navLogo from "../images/nav_logo.png";
import ProfileIcon from "../images/profile.png";
import styled from "styled-components";
import Logo1 from "../images/Logo1.gif";
import Logo2 from "../images/Logo2.png";

// import jwt from "jsonwebtoken";

const ImageGif = styled("img")({
  width: "4rem",
  height: "auto",
  objectFit: "contain",
  "@media (max-width: 600px)": {
    width: "3rem", // Adjust for mobile view
  },
});

const Image2 = styled("img")({
  width: "150px",
  height: "auto",
  objectFit: "contain",
  "@media (max-width: 600px)": {
    width: "100px", // Adjust for mobile view
  },
});

const Navbar = () => {
  const { userInfo } = useSelector((state) => state.signIn);
  // const decodedToken = jwt.decode(userInfo.token);
  // console.log(userInfo);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOutUser = () => {
    dispatch(userLogoutAction());
    window.location.reload(true);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const { isAuthenticated } = useSelector((state) => state.signIn);
  React.useEffect(() => {
    // console.log("isAuthenticated:", isAuthenticated);
    // console.log("userInfo:", userInfo);
  }, [isAuthenticated, userInfo]);

  return (
    <AppBar
      position="static"
      sx={{
        // background:
        //   "linear-gradient(90.24deg, #001322 0.23%, #002645 49.5%, #003B6E 101.61%)",
        backgroundColor: palette.primary.main,
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Container maxWidth="xl" sx={{ p: 1 }}>
        <Toolbar disableGutters>
          <Link to="/" style={{ textDecoration: "none" }}>
            {/* <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                mr: 5,
              }}
            >
              <img
                style={{
                  width: "300px",
                  height: "auto",
                  textAlign: "center",
                  transition: "all ease-out .5s",
                }}
                src={navLogo}
                alt="logo dashboard"
              />
            </Box> */}

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                mr: 5,
                p: 1,
              }}
            >
              <ImageGif src={Logo1} />
              <Image2 src={Logo2} />
            </Box>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: palette.secondary.main,
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
              </Link>
              <Link
                to="https://www.unifiedmentor.com/about"
                style={{
                  textDecoration: "none",
                  color: palette.secondary.main,
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">About</Typography>
                </MenuItem>
              </Link>
              <Link
                to="https://www.unifiedmentor.com/contact-us"
                style={{
                  textDecoration: "none",
                  color: palette.secondary.main,
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Contact</Typography>
                </MenuItem>
              </Link>
              {/* {!userInfo && (
                <Link
                  to="/login"
                  style={{
                    textDecoration: "none",
                    color: palette.secondary.main,
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Login</Typography>
                  </MenuItem>
                </Link>
              )} */}

              {userInfo && (
                <Link
                  to="/ats"
                  style={{
                    textDecoration: "none",
                    color: palette.secondary.main,
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Resume Checker</Typography>
                  </MenuItem>
                </Link>
              )}
            </Menu>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              {/* <img
                style={{
                  width: "150px",
                  height: "auto",
                  textAlign: "center",
                  transition: "all ease-out .5s",
                }}
                src={navLogo}
                alt="logo dashboard"
              /> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ImageGif src={Logo1} />
                <Image2 src={Logo2} />
              </Box>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* menu desktop */}

            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Home
              </Button>
            </Link>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <Link
                to="https://www.unifiedmentor.com/about"
                style={{ color: "white", textDecoration: "none" }}
              >
                About
              </Link>
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <Link
                to="https://www.unifiedmentor.com/contact-us"
                style={{ color: "white", textDecoration: "none" }}
              >
                Contact
              </Link>
            </Button>

            {userInfo && (
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Link
                  to="/ats"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Resume Checker
                </Link>
              </Button>
            )}
          </Box>
          {/* <IconButton
            sx={{ mr: 4 }}
            onClick={() => dispatch(toggleAction())}
          >
            {palette.mode === "dark" ? (
              <DarkMode sx={{ color: "#ffffff", fontSize: "25px" }} />
            ) : (
              <LightMode sx={{ color: "#ffffff", fontSize: "25px" }} />
            )}
          </IconButton> */}
          {/* <Box sx={{ px: { xs: 2, md: 5 } }}>
            <Typography
              textAlign="center"
              sx={{
                fontSize: { xs: "12px", md: "20px" },
                fontWeight: "bold",
                color: palette.primary.white,
                transition: "all ease-out .5s",
              }}
            >
              Hi, {userInfo ? userInfo.name : "Dear User"}
            </Typography>
          </Box> */}
          {/* <Button
            onClick={handleCloseNavMenu}
            sx={{ my: 2, mx: 5, color: "white", display: "block" }}
          >
            <Link
              to="/user/dashboard"
              style={{ color: "white", textDecoration: "none" }}
            >
              dashboard
            </Link>
          </Button> */}
          <Box
            sx={{
              flexGrow: 0,
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography>
              {/* {isAuthenticated !== undefined && isAuthenticated !== false
                ? userInfo?.role === 1
                  ? "Hi, Admin"
                  : "Hi, User"
                : ""} */}

              {userInfo
                ? userInfo?.role === 1
                  ? "Hi, Admin"
                  : "Hi, User"
                : ""}
            </Typography>

            {userInfo?.success === true ? (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ bgcolor: palette.primary.main }}
                    alt="User Avatar"
                    src={ProfileIcon}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <Button
                sx={{
                  color: "#000",
                  background: "#fff",
                  fontSize: "16px",
                  padding: "5px 25px",
                  borderRadius: "50px",
                  fontWeight: "bold",
                  border: "1px solid #fff",
                  textTransform: "none",
                  "&:hover": {
                    background: "#3C1F7D",
                    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                    color: "#FFFFFF",
                  },
                }}
                aria-label="account of current user"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            )}

            <Menu
              PaperProps={{
                sx: {
                  "& .MuiMenu-list": {
                    bgcolor: palette.primary.white,
                    color: palette.primary.main,
                  },
                },
              }}
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {!userInfo ? (
                <Link
                  style={{
                    textDecoration: "none",
                    color: palette.secondary.main,
                  }}
                  to="/login"
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Log In</Typography>
                  </MenuItem>
                </Link>
              ) : (
                <>
                  {userInfo.role === 1 ? (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: palette.secondary.main,
                      }}
                      to="/admin/dashboard"
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">
                          Admin Dashboard
                        </Typography>
                      </MenuItem>
                    </Link>
                  ) : (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: palette.secondary.main,
                      }}
                      to="/user/dashboard"
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">
                          User Dashboard
                        </Typography>
                      </MenuItem>
                    </Link>
                  )}
                  <MenuItem onClick={logOutUser}>
                    <Typography
                      style={{
                        textDecoration: "none",
                        color: palette.secondary.main,
                      }}
                      textAlign="center"
                    >
                      Log Out
                    </Typography>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
