import {
  CREATE_JOB_TYPE_FAIL,
  CREATE_JOB_TYPE_REQUEST,
  CREATE_JOB_TYPE_RESET,
  CREATE_JOB_TYPE_SUCCESS,
  DELETE_JOB_TYPE_FAIL,
  DELETE_JOB_TYPE_REQUEST,
  DELETE_JOB_TYPE_RESET,
  DELETE_JOB_TYPE_SUCCESS,
  JOB_TYPE_LOAD_FAIL,
  JOB_TYPE_LOAD_REQUEST,
  JOB_TYPE_LOAD_RESET,
  JOB_TYPE_LOAD_SUCCESS,
  SINGLE_LOAD_JOB_TYPE_FAIL,
  SINGLE_LOAD_JOB_TYPE_REQUEST,
  SINGLE_LOAD_JOB_TYPE_RESET,
  SINGLE_LOAD_JOB_TYPE_SUCCESS,
} from "../constants/jobTypeConstant";

// load job type reducer
export const loadJobTypeReducer = (state = { jobType: [] }, action) => {
  switch (action.type) {
    case JOB_TYPE_LOAD_REQUEST:
      return { loading: true };
    case JOB_TYPE_LOAD_SUCCESS:
      return {
        loading: false,
        jobType: action.payload.jobT,
      };
    case JOB_TYPE_LOAD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case JOB_TYPE_LOAD_RESET:
      return {};
    default:
      return state;
  }
};

// load single job type reducer
export const loadTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_LOAD_JOB_TYPE_REQUEST:
      return { loading: true };
    case SINGLE_LOAD_JOB_TYPE_SUCCESS:
      return {
        loading: false,
        jobType: action.payload.jobT,
      };
    case SINGLE_LOAD_JOB_TYPE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SINGLE_LOAD_JOB_TYPE_RESET:
      return {};
    default:
      return state;
  }
};

// delete category/type by id
export const deleteTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_JOB_TYPE_REQUEST:
      return { loading: true };
    case DELETE_JOB_TYPE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        message: action.payload.message,
      };
    case DELETE_JOB_TYPE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_JOB_TYPE_RESET:
      return {};
    default:
      return state;
  }
};

// Load job type reducer
export const createJobTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_JOB_TYPE_REQUEST:
      return { loading: true };
    case CREATE_JOB_TYPE_SUCCESS:
      return {
        loading: false,
        jobType: action.payload,
      };
    case CREATE_JOB_TYPE_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_JOB_TYPE_RESET:
      return {};
    default:
      return state;
  }
};
